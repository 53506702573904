import { Typography, Stack, CircularProgress, Button } from "@mui/material";
import { CodeBlock } from "../../../../components/CodeBlock";
import { useWizard } from "../../../../components/Wizard/WizardContext";
import { AgentInstallFormValues } from "./InstallWizard";
import { useInstallWizard } from "./InstallWizardContext";
import {
  platformIsKubernetes,
  platformIsOpenShift,
  platformIsContainer,
  AGENT_LABEL_INSTALL_ID,
} from "./utils";
import { Platform } from "./types";
import { gql } from "@apollo/client";
import { useGetInstalledAgentsQuery } from "../../../../graphql/generated";
import { useSnackbar } from "notistack";
import { renderAgentStatus } from "../../../../components/Tables/utils";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./install-wizard.module.scss";
import colors from "../../../../styles/colors";

gql`
  query GetInstalledAgents($selector: String!) {
    agents(selector: $selector) {
      agents {
        id
        name
        status
        version
      }
    }
  }
`;

export const InstallYourAgents: React.FC = () => {
  const [showPolling, setShowPolling] = useState(false);

  const { formValues, goToStep } = useWizard<AgentInstallFormValues>();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { platform, secondaryPlatform } = formValues;
  const { command, installationId } = useInstallWizard();

  const { data, startPolling } = useGetInstalledAgentsQuery({
    onError(error) {
      console.error(error);
      enqueueSnackbar("Failed to get installed agents", {
        variant: "error",
      });
    },
    variables: {
      selector: `${AGENT_LABEL_INSTALL_ID}=${installationId}`,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    startPolling(5000);
  }, [startPolling]);

  function renderK8sInstallText() {
    return (
      <Typography>
        To deploy the Agent to Kubernetes:
        <ol>
          <li>Copy the YAML below to a file.</li>
          <li>
            Apply with kubectl: <code>kubectl apply -f &lt;filename&gt;</code>
          </li>
        </ol>
      </Typography>
    );
  }

  function renderOpenShiftInstallText() {
    return (
      <Typography>
        To deploy the agent to OpenShift:
        <ol>
          <li>Copy the YAML below to a file.</li>
          <li>
            Apply with oc: <code>oc apply -f &lt;filename&gt;</code>
          </li>
        </ol>
      </Typography>
    );
  }

  function renderNormalInstallCopy() {
    let platformName: string;
    switch (platform) {
      case Platform.macOS:
        platformName = "macOS";
        break;
      default:
        platformName = platform.charAt(0).toUpperCase() + platform.slice(1);
    }

    return (
      <>
        <Typography>
          For {platformName}, BindPlane Agents run directly on the host. The
          command below will download and execute the Agent installation script.
        </Typography>
        <Typography>
          Run the following command on each {platformName} host you want to ship
          telemetry from.
        </Typography>
      </>
    );
  }

  return (
    <Stack height="100%" spacing={2}>
      <Stack spacing={2} flexGrow={1}>
        <Typography variant="h6" fontWeight={600}>
          Now it&apos;s time to get some Agents added to your environment!
        </Typography>

        {platformIsKubernetes(secondaryPlatform ?? platform) &&
          renderK8sInstallText()}
        {platformIsOpenShift(secondaryPlatform ?? platform) &&
          renderOpenShiftInstallText()}
        {!platformIsContainer(secondaryPlatform ?? platform) &&
          renderNormalInstallCopy()}

        <CodeBlock
          value={command ?? ""}
          onCopyClick={() => setShowPolling(true)}
        />

        <Typography>
          Agents will appear below after they are installed and start up.
        </Typography>

        <div className={styles.tableBox}>
          <table className={styles.table}>
            <thead className={styles.th}>
              <tr>
                <th>Agent Name</th>
                <th>Status</th>
                <th>Version</th>
              </tr>
            </thead>
            <tbody>
              {data?.agents.agents.map((a) => {
                return (
                  <tr key={a.id} className={styles.tr}>
                    <td>{a.name}</td>
                    <td>{renderAgentStatus(a.status)}</td>
                    <td>{a.version}</td>
                  </tr>
                );
              })}

              <tr className={styles.tr}>
                <td colSpan={3}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    height={20}
                  >
                    {showPolling && (
                      <>
                        <CircularProgress size={15} />{" "}
                        <Typography fontSize={15} color={colors.darkGray}>
                          Searching for new Agents...
                        </Typography>
                      </>
                    )}
                  </Stack>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Stack>
      <Stack spacing={2} direction="row" justifyContent="space-between">
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => goToStep(0)}
        >
          Back
        </Button>
        <Button variant="contained" onClick={() => navigate("/agents")}>
          Return to all Agents
        </Button>
      </Stack>
    </Stack>
  );
};
