import { LoadingButton } from "@mui/lab";
import {
  DialogProps,
  Typography,
  TextField,
  DialogActions,
  Button,
  Box,
  Stack,
  IconButton,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Role, useNewInvitationMutation } from "../../graphql/generated";
import { Errors, Touched, Values } from "./types";

import { CopyIcon } from "../Icons";

interface InviteFormProps {
  values: Values;
  touched: Touched;
  errors: Errors;
  onClose: DialogProps["onClose"];
  onCancel: () => void;
  onValueChange: (fieldName: string, value: string) => void;
  onErrorChange: (fieldName: string, error: string | null) => void;
  onTouchedChange: (fieldName: string, touched: boolean) => void;
}

// Exported for testing
export const INVALID_EMAIL_MSG = "Hmm, that doesn't look like an email.";

export const InviteByEmailForm: React.FC<InviteFormProps> = ({
  values,
  touched,
  errors,
  onClose,
  onCancel,
  onValueChange,
  onErrorChange,
  onTouchedChange,
}) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [inviteWithEmail] = useNewInvitationMutation({
    onError: (error) => {
      console.error({ error });
      if ((error.message = "failed to send invitation email")) {
        enqueueSnackbar("Failed to send invitation email.", {
          variant: "error",
          key: "invite-user-error",
        });
        onClose && onClose({}, "backdropClick");
        return;
      }
      enqueueSnackbar("Oops! Something went wrong.", {
        variant: "error",
        key: "invite-user-error",
      });
    },
    onCompleted: () => {
      enqueueSnackbar("User invited!", {
        variant: "success",
        key: "invite-user-success",
      });

      onClose && onClose({}, "backdropClick");
    },
  });

  async function handleInvite() {
    if (!isValidEmail(values.email)) {
      onErrorChange("email", INVALID_EMAIL_MSG);
      return;
    }

    setLoading(true);
    await inviteWithEmail({
      variables: {
        input: {
          email: values.email,
          note: `Sent via email to ${values.email}`,
          role: values.role,
        },
      },
    });
    setLoading(false);
  }

  function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    onValueChange("email", e.target.value);

    if (touched.email) {
      onErrorChange(
        "email",
        isValidEmail(e.target.value) ? null : INVALID_EMAIL_MSG
      );
    }
  }

  function handleRoleChange(e: SelectChangeEvent<Role>) {
    onValueChange("role", e.target.value);
  }

  return (
    <>
      <Typography fontWeight={600} marginBottom={2}>
        Invite Users by Email
      </Typography>

      <Typography variant="body2" marginBottom={1}>
        Add New User
      </Typography>

      <Stack direction="row" alignItems={"center"} spacing={2} marginBottom={2}>
        <TextField
          autoFocus
          fullWidth
          size="small"
          placeholder="Invite New User by Email Address"
          aria-label="Email Address"
          value={values.email}
          onChange={handleEmailChange}
          error={errors.email !== null}
          helperText={errors.email}
          onBlur={() => onTouchedChange("email", true)}
        />
        <Select size="small" value={values.role} onChange={handleRoleChange}>
          <MenuItem value={Role.Admin}>Admin</MenuItem>
          <MenuItem value={Role.User}>User</MenuItem>
          <MenuItem value={Role.Viewer}>Viewer</MenuItem>
        </Select>
      </Stack>
      <DialogActions>
        <Button variant={"outlined"} color="secondary" onClick={onCancel}>
          Close
        </Button>
        <LoadingButton
          loading={loading}
          variant={"contained"}
          onClick={handleInvite}
        >
          Invite
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export const InviteByLinkForm: React.FC<InviteFormProps> = ({
  values,
  onValueChange,
  onCancel,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [currentLink, setCurrentLink] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [createNewInvitation] = useNewInvitationMutation({
    onCompleted: (data) => {
      enqueueSnackbar("Invitation link created!", {
        variant: "success",
        key: "invite-user-success",
      });

      onValueChange("note", "");
      setCurrentLink(data.newInvitation.link);
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar("Oops! Something went wrong.", {
        variant: "error",
        key: "invite-user-error",
      });
    },
  });

  function handleNoteValueChange(e: React.ChangeEvent<HTMLInputElement>) {
    onValueChange("note", e.target.value);
  }

  function handleRoleChange(e: SelectChangeEvent<Role>) {
    onValueChange("role", e.target.value);
  }

  async function handleCreateNewLinkClick() {
    setLoading(true);
    await createNewInvitation({
      variables: {
        input: {
          note: values.note,
          role: values.role,
        },
      },
    });
    setLoading(false);
  }

  return (
    <>
      <Typography fontWeight={600}>Invite Users with Link</Typography>

      <Typography variant="body2" marginBottom={2}>
        Invitation links are valid for 24 hours and can only be claimed once.
      </Typography>

      {!currentLink && (
        <Stack
          direction="row"
          alignItems={"start"}
          spacing={2}
          marginBottom={2}
        >
          <TextField
            autoFocus
            fullWidth
            size="small"
            placeholder="(optional)"
            value={values.note}
            onChange={handleNoteValueChange}
            helperText="Provide an optional note to identify this invitation."
          />
          <Select size="small" value={values.role} onChange={handleRoleChange}>
            <MenuItem value={Role.Admin}>Admin</MenuItem>
            <MenuItem value={Role.User}>User</MenuItem>
            <MenuItem value={Role.Viewer}>Viewer</MenuItem>
          </Select>
        </Stack>
      )}

      {currentLink && (
        <Box marginY={2}>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"center"}
            spacing={2}
          >
            <TextField size="small" value={currentLink} fullWidth />
            <CopyToClipboard
              text={currentLink}
              onCopy={() => {
                enqueueSnackbar("Invitation link copied!", {
                  variant: "success",
                  key: "invitation-link-copied",
                });
              }}
            >
              <IconButton>
                <CopyIcon />
              </IconButton>
            </CopyToClipboard>
          </Stack>
        </Box>
      )}
      <DialogActions>
        <Button variant={"outlined"} color="secondary" onClick={onCancel}>
          Close
        </Button>
        {!currentLink && (
          <LoadingButton
            variant={"contained"}
            loading={loading}
            onClick={handleCreateNewLinkClick}
          >
            Generate New Link
          </LoadingButton>
        )}
        {currentLink && (
          <Button
            variant={"contained"}
            color="primary"
            onClick={() => setCurrentLink(null)}
          >
            Create New Link
          </Button>
        )}
      </DialogActions>
    </>
  );
};

/**
 * This regex is from https://medium.com/@jgratereaux/validate-emails-with-regular-expressions-2636ce08ddc2
 * @param email
 * @returns boolean
 */
function isValidEmail(email: string): boolean {
  const regex =
    /^(([^<>()[\]\\.,;:\s@”]+(\.[^<>()[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}
