import { TwoStepBreadcrumb } from "../../../components/CardContainer";
import { withEENavBar } from "../../../components/EENavBar";
import { withRBAC } from "../../../contexts/RBAC";
import { withRequireLogin } from "../../../contexts/RequireLogin";
import { InstallWizard } from "./InstallWizard";

export const AgentInstallPageContent: React.FC = () => {
  return (
    <>
      <TwoStepBreadcrumb
        navLabel={"Agents"}
        navLink={"/agents"}
        current={"Install Agents"}
        spacing="short"
      />
      <InstallWizard />
    </>
  );
};

export const AgentInstallPage = withRequireLogin(
  withRBAC(withEENavBar(AgentInstallPageContent))
);
