import React from "react";
import colors from "../../styles/colors";

/**
 * Black and White version of the BindPlaneOP Logo.
 */
export const BindPlaneOPLogo: React.FC<React.ComponentProps<"svg">> = (
  props
) => {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1249 317"
      {...props}
    >
      <rect fill="none" x="-4" y="18" width="1054" height="317" />
      <rect
        fill="#fff"
        x="1053.02249"
        y="46"
        width="192"
        height="192"
        rx="25"
        ry="25"
      />
      <path
        fill="#69b6e3"
        d="M1149.40756,113.455c0,8.231-1.5,15.30699-4.51001,21.23-3.01001,5.897-7.14001,10.439-12.39001,13.62601-5.21997,3.162-11.15002,4.743-17.80005,4.743-6.67993,0-12.64001-1.58099-17.88-4.743-5.21997-3.18701-9.33997-7.742-12.34998-13.664s-4.51001-12.98601-4.51001-21.192c0-8.232,1.5-15.29601,4.51001-21.193,3.01001-5.9223,7.13-10.4644,12.34998-13.6263,5.23999-3.1871,11.20007-4.78059,17.88-4.78059,6.65002,0,12.58008,1.5935,17.80005,4.78059,5.25,3.1619,9.38,7.704,12.39001,13.6263,3.01001,5.897,4.51001,12.961,4.51001,21.193Zm-11.51001,0c0-6.274-1.02002-11.557-3.05005-15.848-2.01001-4.316-4.77002-7.578-8.28003-9.78671-3.48999-2.2334-7.43994-3.3501-11.85999-3.3501-4.43994,0-8.41003,1.1167-11.90002,3.3501-3.47998,2.2087-6.23999,5.4707-8.27991,9.78671-2.01001,4.291-3.01001,9.574-3.01001,15.848,0,6.27299,1,11.56799,3.01001,15.884,2.03992,4.29199,4.79993,7.554,8.27991,9.787,3.48999,2.209,7.46008,3.31299,11.90002,3.31299,4.42004,0,8.37-1.10399,11.85999-3.31299,3.51001-2.233,6.27002-5.49501,8.28003-9.787,2.03003-4.31601,3.05005-9.61101,3.05005-15.884Zm26.0199,38.545V74.9091h27.47009c6,0,10.96997,1.0916,14.90991,3.2748,3.94006,2.1833,6.89001,5.1696,8.8501,8.9589,1.94995,3.7642,2.92993,8.0052,2.92993,12.7232,0,4.743-.98999,9.009-2.96997,12.798-1.95996,3.76399-4.92004,6.75099-8.88,8.959-3.93994,2.183-8.90002,3.27499-14.87,3.27499h-18.90002v-9.86199h17.83997c3.79004,0,6.87-.653,9.2301-1.958,2.34998-1.33,4.08997-3.137,5.18994-5.42001,1.09998-2.284,1.66003-4.881,1.66003-7.792s-.56006-5.49599-1.66003-7.754c-1.09998-2.259-2.84998-4.028-5.22998-5.308-2.35999-1.2799-5.46997-1.9198-9.33997-1.9198h-14.6001v67.1158h-11.63Z"
      />
      <g>
        <path
          fill="#5a595c"
          d="M29.93408,133.74463c8.0332-13.73438,24.87744-20.73145,42.49854-20.73145,33.4292,0,61.15723,24.35938,61.15723,62.97119,0,38.35254-28.24609,63.23047-61.41602,63.23047-16.84424,0-34.20654-7.51562-42.23975-21.25v8.0332c0,6.47852-5.18311,11.9209-11.92041,11.9209-6.47852,0-11.66162-5.44238-11.66162-11.9209V60.40771c0-6.47852,5.18311-11.92041,11.66162-11.92041,6.7373,0,11.92041,5.44189,11.92041,11.92041v73.33691Zm40.42578,82.40674c21.76758,0,39.12988-16.58496,39.12988-40.42676,0-24.09961-17.3623-39.64795-39.12988-39.64795-19.43555,0-40.16699,13.73438-40.16699,39.64795,0,25.65527,18.6582,40.42676,40.16699,40.42676Z"
        />
        <path
          fill="#5a595c"
          d="M160.01904,56.00244c9.06982,0,16.32568,6.47852,16.32568,15.28906,0,8.81104-7.25586,15.28955-16.32568,15.28955-8.81055,0-16.32568-6.47852-16.32568-15.28955,0-8.81055,7.51514-15.28906,16.32568-15.28906Zm11.92041,70.22705c0-6.47852-5.18262-11.92041-11.92041-11.92041-6.47852,0-11.66113,5.44189-11.66113,11.92041v99.76855c0,6.47852,5.18262,11.9209,11.66113,11.9209,6.73779,0,11.92041-5.44238,11.92041-11.9209V126.22949Z"
        />
        <path
          fill="#5a595c"
          d="M217.28516,133.74463c7.25586-14.51221,24.61816-20.73145,37.83447-20.73145,30.06006,0,46.64502,19.69482,46.64502,53.64209v59.34277c0,6.47852-5.18262,11.9209-11.92041,11.9209-6.47852,0-11.66113-5.44238-11.66113-11.9209v-57.78809c0-20.21289-10.10645-32.1333-26.69141-32.1333-17.10303,0-34.20654,9.58838-34.20654,35.24268v54.67871c0,6.47852-5.18262,11.9209-11.92041,11.9209-6.47852,0-11.66113-5.44238-11.66113-11.9209V126.22949c0-6.47852,5.18262-11.92041,11.66113-11.92041,6.73779,0,11.92041,5.44189,11.92041,11.92041v7.51514Z"
        />
        <path
          fill="#5a595c"
          d="M419.15381,60.40771c0-6.47852,5.18262-11.92041,11.92041-11.92041,6.47852,0,11.66113,5.44189,11.66113,11.92041V225.99805c0,6.47852-5.18262,11.9209-11.66113,11.9209-6.73779,0-11.92041-5.44238-11.92041-11.9209v-8.0332c-8.0332,13.73438-25.396,21.25-42.23975,21.25-33.16992,0-61.4165-24.87793-61.4165-63.23047,0-38.61182,27.72803-62.97119,61.15723-62.97119,17.62158,0,34.46582,6.99707,42.49902,20.73145V60.40771Zm-40.42578,75.66895c-21.76807,0-39.13037,15.54834-39.13037,39.64795,0,23.8418,17.3623,40.42676,39.13037,40.42676,21.5083,0,40.1665-14.77148,40.1665-40.42676,0-25.91357-20.73096-39.64795-40.1665-39.64795Z"
        />
        <path
          fill="#fff"
          d="M488.85938,284.30469c0,6.47852-5.18262,11.9209-11.92041,11.9209-6.47852,0-11.66162-5.44238-11.66162-11.9209V126.22949c0-6.47852,5.18311-11.92041,11.66162-11.92041,6.73779,0,11.92041,5.44189,11.92041,11.92041v8.29248c8.0332-13.73438,25.39551-21.50879,42.23975-21.50879,33.16992,0,60.63867,25.13672,60.63867,63.48877s-27.46875,62.71289-60.63867,62.71289c-16.84424,0-34.20654-6.73828-42.23975-20.73145v65.82129Zm39.90771-68.15332c21.76758,0,39.12988-15.29004,39.12988-39.38965s-17.3623-40.68506-39.12988-40.68506c-21.24951,0-39.64844,15.03027-39.64844,40.68506,0,25.91406,20.21289,39.38965,39.64844,39.38965Z"
        />
        <path
          fill="#fff"
          d="M630.34668,225.99805c0,6.47852-5.18262,11.9209-11.91992,11.9209-6.47852,0-11.66162-5.44238-11.66162-11.9209V60.40771c0-6.47852,5.18311-11.92041,11.66162-11.92041,6.7373,0,11.91992,5.44189,11.91992,11.92041V225.99805Z"
        />
        <path
          fill="#fff"
          d="M749.29199,217.96484c-8.0332,13.73438-25.6543,21.25-42.49902,21.25-33.16992,0-61.41602-24.87793-61.41602-63.23047,0-38.61182,28.24609-62.97119,61.41602-62.97119,16.84473,0,34.46582,6.99707,42.49902,20.73145v-7.51514c0-6.47852,5.18262-11.92041,11.66113-11.92041,6.73828,0,11.9209,5.44189,11.9209,11.92041v99.76855c0,6.47852-5.18262,11.9209-11.9209,11.9209-6.47852,0-11.66113-5.44238-11.66113-11.9209v-8.0332Zm-40.42578-81.88818c-21.76758,0-39.38867,15.54834-39.38867,39.64795,0,23.8418,17.62109,40.42676,39.38867,40.42676,21.50879,0,40.16699-14.77148,40.16699-40.42676,0-25.91357-20.73145-39.64795-40.16699-39.64795Z"
        />
        <path
          fill="#fff"
          d="M818.22363,133.74463c7.25586-14.51221,24.61816-20.73145,37.83398-20.73145,30.06055,0,46.64551,19.69482,46.64551,53.64209v59.34277c0,6.47852-5.18262,11.9209-11.9209,11.9209-6.47852,0-11.66113-5.44238-11.66113-11.9209v-57.78809c0-20.21289-10.10645-32.1333-26.69141-32.1333-17.10352,0-34.20605,9.58838-34.20605,35.24268v54.67871c0,6.47852-5.18359,11.9209-11.9209,11.9209-6.47852,0-11.66113-5.44238-11.66113-11.9209V126.22949c0-6.47852,5.18262-11.92041,11.66113-11.92041,6.7373,0,11.9209,5.44189,11.9209,11.92041v7.51514Z"
        />
        <path
          fill="#fff"
          d="M1029.16016,185.05371h-89.14355c2.85059,20.21387,18.6582,32.91113,39.12988,32.91113,14.51172,0,22.54492-3.62793,30.83789-12.69727,4.40527-4.92383,11.14258-6.47852,15.80762-3.11035,4.66406,3.62793,5.70117,10.625,2.07324,14.77148-11.66113,15.03027-29.80176,22.28613-49.49609,22.28613-34.46582,0-61.93457-25.13672-61.93457-63.49023s27.46875-62.71143,61.93457-62.71143,60.12012,24.35938,60.12012,62.71143c0,5.44238-4.40527,9.3291-9.3291,9.3291Zm-13.99316-18.39844c-2.85059-20.21289-17.3623-32.39258-36.79785-32.39258-19.9541,0-35.50195,12.17969-38.35254,32.39258h75.15039Z"
        />
      </g>
    </svg>
  );
};

export const BindPlaneOPLogoWhite: React.FC<React.ComponentProps<"svg">> = (
  props
) => {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1249 317"
      {...props}
    >
      <rect fill="none" x="-4" y="18" width="1054" height="317" />
      <rect
        fill="#fff"
        x="1053.02249"
        y="46"
        width="192"
        height="192"
        rx="25"
        ry="25"
      />
      <path
        fill="#69b6e3"
        d="M1149.40756,113.455c0,8.231-1.5,15.30699-4.51001,21.23-3.01001,5.897-7.14001,10.439-12.39001,13.62601-5.21997,3.162-11.15002,4.743-17.80005,4.743-6.67993,0-12.64001-1.58099-17.88-4.743-5.21997-3.18701-9.33997-7.742-12.34998-13.664s-4.51001-12.98601-4.51001-21.192c0-8.232,1.5-15.29601,4.51001-21.193,3.01001-5.9223,7.13-10.4644,12.34998-13.6263,5.23999-3.1871,11.20007-4.78059,17.88-4.78059,6.65002,0,12.58008,1.5935,17.80005,4.78059,5.25,3.1619,9.38,7.704,12.39001,13.6263,3.01001,5.897,4.51001,12.961,4.51001,21.193Zm-11.51001,0c0-6.274-1.02002-11.557-3.05005-15.848-2.01001-4.316-4.77002-7.578-8.28003-9.78671-3.48999-2.2334-7.43994-3.3501-11.85999-3.3501-4.43994,0-8.41003,1.1167-11.90002,3.3501-3.47998,2.2087-6.23999,5.4707-8.27991,9.78671-2.01001,4.291-3.01001,9.574-3.01001,15.848,0,6.27299,1,11.56799,3.01001,15.884,2.03992,4.29199,4.79993,7.554,8.27991,9.787,3.48999,2.209,7.46008,3.31299,11.90002,3.31299,4.42004,0,8.37-1.10399,11.85999-3.31299,3.51001-2.233,6.27002-5.49501,8.28003-9.787,2.03003-4.31601,3.05005-9.61101,3.05005-15.884Zm26.0199,38.545V74.9091h27.47009c6,0,10.96997,1.0916,14.90991,3.2748,3.94006,2.1833,6.89001,5.1696,8.8501,8.9589,1.94995,3.7642,2.92993,8.0052,2.92993,12.7232,0,4.743-.98999,9.009-2.96997,12.798-1.95996,3.76399-4.92004,6.75099-8.88,8.959-3.93994,2.183-8.90002,3.27499-14.87,3.27499h-18.90002v-9.86199h17.83997c3.79004,0,6.87-.653,9.2301-1.958,2.34998-1.33,4.08997-3.137,5.18994-5.42001,1.09998-2.284,1.66003-4.881,1.66003-7.792s-.56006-5.49599-1.66003-7.754c-1.09998-2.259-2.84998-4.028-5.22998-5.308-2.35999-1.2799-5.46997-1.9198-9.33997-1.9198h-14.6001v67.1158h-11.63Z"
      />
      <g>
        <path
          fill="#fff"
          d="M29.93408,133.74463c8.0332-13.73438,24.87744-20.73145,42.49854-20.73145,33.4292,0,61.15723,24.35938,61.15723,62.97119,0,38.35254-28.24609,63.23047-61.41602,63.23047-16.84424,0-34.20654-7.51562-42.23975-21.25v8.0332c0,6.47852-5.18311,11.9209-11.92041,11.9209-6.47852,0-11.66162-5.44238-11.66162-11.9209V60.40771c0-6.47852,5.18311-11.92041,11.66162-11.92041,6.7373,0,11.92041,5.44189,11.92041,11.92041v73.33691Zm40.42578,82.40674c21.76758,0,39.12988-16.58496,39.12988-40.42676,0-24.09961-17.3623-39.64795-39.12988-39.64795-19.43555,0-40.16699,13.73438-40.16699,39.64795,0,25.65527,18.6582,40.42676,40.16699,40.42676Z"
        />
        <path
          fill="#fff"
          d="M160.01904,56.00244c9.06982,0,16.32568,6.47852,16.32568,15.28906,0,8.81104-7.25586,15.28955-16.32568,15.28955-8.81055,0-16.32568-6.47852-16.32568-15.28955,0-8.81055,7.51514-15.28906,16.32568-15.28906Zm11.92041,70.22705c0-6.47852-5.18262-11.92041-11.92041-11.92041-6.47852,0-11.66113,5.44189-11.66113,11.92041v99.76855c0,6.47852,5.18262,11.9209,11.66113,11.9209,6.73779,0,11.92041-5.44238,11.92041-11.9209V126.22949Z"
        />
        <path
          fill="#fff"
          d="M217.28516,133.74463c7.25586-14.51221,24.61816-20.73145,37.83447-20.73145,30.06006,0,46.64502,19.69482,46.64502,53.64209v59.34277c0,6.47852-5.18262,11.9209-11.92041,11.9209-6.47852,0-11.66113-5.44238-11.66113-11.9209v-57.78809c0-20.21289-10.10645-32.1333-26.69141-32.1333-17.10303,0-34.20654,9.58838-34.20654,35.24268v54.67871c0,6.47852-5.18262,11.9209-11.92041,11.9209-6.47852,0-11.66113-5.44238-11.66113-11.9209V126.22949c0-6.47852,5.18262-11.92041,11.66113-11.92041,6.73779,0,11.92041,5.44189,11.92041,11.92041v7.51514Z"
        />
        <path
          fill="#fff"
          d="M419.15381,60.40771c0-6.47852,5.18262-11.92041,11.92041-11.92041,6.47852,0,11.66113,5.44189,11.66113,11.92041V225.99805c0,6.47852-5.18262,11.9209-11.66113,11.9209-6.73779,0-11.92041-5.44238-11.92041-11.9209v-8.0332c-8.0332,13.73438-25.396,21.25-42.23975,21.25-33.16992,0-61.4165-24.87793-61.4165-63.23047,0-38.61182,27.72803-62.97119,61.15723-62.97119,17.62158,0,34.46582,6.99707,42.49902,20.73145V60.40771Zm-40.42578,75.66895c-21.76807,0-39.13037,15.54834-39.13037,39.64795,0,23.8418,17.3623,40.42676,39.13037,40.42676,21.5083,0,40.1665-14.77148,40.1665-40.42676,0-25.91357-20.73096-39.64795-40.1665-39.64795Z"
        />
        <path
          fill="#fff"
          d="M488.85938,284.30469c0,6.47852-5.18262,11.9209-11.92041,11.9209-6.47852,0-11.66162-5.44238-11.66162-11.9209V126.22949c0-6.47852,5.18311-11.92041,11.66162-11.92041,6.73779,0,11.92041,5.44189,11.92041,11.92041v8.29248c8.0332-13.73438,25.39551-21.50879,42.23975-21.50879,33.16992,0,60.63867,25.13672,60.63867,63.48877s-27.46875,62.71289-60.63867,62.71289c-16.84424,0-34.20654-6.73828-42.23975-20.73145v65.82129Zm39.90771-68.15332c21.76758,0,39.12988-15.29004,39.12988-39.38965s-17.3623-40.68506-39.12988-40.68506c-21.24951,0-39.64844,15.03027-39.64844,40.68506,0,25.91406,20.21289,39.38965,39.64844,39.38965Z"
        />
        <path
          fill="#fff"
          d="M630.34668,225.99805c0,6.47852-5.18262,11.9209-11.91992,11.9209-6.47852,0-11.66162-5.44238-11.66162-11.9209V60.40771c0-6.47852,5.18311-11.92041,11.66162-11.92041,6.7373,0,11.91992,5.44189,11.91992,11.92041V225.99805Z"
        />
        <path
          fill="#fff"
          d="M749.29199,217.96484c-8.0332,13.73438-25.6543,21.25-42.49902,21.25-33.16992,0-61.41602-24.87793-61.41602-63.23047,0-38.61182,28.24609-62.97119,61.41602-62.97119,16.84473,0,34.46582,6.99707,42.49902,20.73145v-7.51514c0-6.47852,5.18262-11.92041,11.66113-11.92041,6.73828,0,11.9209,5.44189,11.9209,11.92041v99.76855c0,6.47852-5.18262,11.9209-11.9209,11.9209-6.47852,0-11.66113-5.44238-11.66113-11.9209v-8.0332Zm-40.42578-81.88818c-21.76758,0-39.38867,15.54834-39.38867,39.64795,0,23.8418,17.62109,40.42676,39.38867,40.42676,21.50879,0,40.16699-14.77148,40.16699-40.42676,0-25.91357-20.73145-39.64795-40.16699-39.64795Z"
        />
        <path
          fill="#fff"
          d="M818.22363,133.74463c7.25586-14.51221,24.61816-20.73145,37.83398-20.73145,30.06055,0,46.64551,19.69482,46.64551,53.64209v59.34277c0,6.47852-5.18262,11.9209-11.9209,11.9209-6.47852,0-11.66113-5.44238-11.66113-11.9209v-57.78809c0-20.21289-10.10645-32.1333-26.69141-32.1333-17.10352,0-34.20605,9.58838-34.20605,35.24268v54.67871c0,6.47852-5.18359,11.9209-11.9209,11.9209-6.47852,0-11.66113-5.44238-11.66113-11.9209V126.22949c0-6.47852,5.18262-11.92041,11.66113-11.92041,6.7373,0,11.9209,5.44189,11.9209,11.92041v7.51514Z"
        />
        <path
          fill="#fff"
          d="M1029.16016,185.05371h-89.14355c2.85059,20.21387,18.6582,32.91113,39.12988,32.91113,14.51172,0,22.54492-3.62793,30.83789-12.69727,4.40527-4.92383,11.14258-6.47852,15.80762-3.11035,4.66406,3.62793,5.70117,10.625,2.07324,14.77148-11.66113,15.03027-29.80176,22.28613-49.49609,22.28613-34.46582,0-61.93457-25.13672-61.93457-63.49023s27.46875-62.71143,61.93457-62.71143,60.12012,24.35938,60.12012,62.71143c0,5.44238-4.40527,9.3291-9.3291,9.3291Zm-13.99316-18.39844c-2.85059-20.21289-17.3623-32.39258-36.79785-32.39258-19.9541,0-35.50195,12.17969-38.35254,32.39258h75.15039Z"
        />
      </g>
    </svg>
  );
};

export const BindPlaneLogoBlueGrey: React.FC<React.ComponentProps<"svg">> = (
  props
) => {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1249 317"
      {...props}
    >
      <rect fill="none" x="-4" y="18" width="1054" height="317" />
      <rect
        fill="#6AB6E3"
        x="1053.02249"
        y="46"
        width="192"
        height="192"
        rx="25"
        ry="25"
      />
      <path
        fill="#fff"
        d="M1149.40756,113.455c0,8.231-1.5,15.30699-4.51001,21.23-3.01001,5.897-7.14001,10.439-12.39001,13.62601-5.21997,3.162-11.15002,4.743-17.80005,4.743-6.67993,0-12.64001-1.58099-17.88-4.743-5.21997-3.18701-9.33997-7.742-12.34998-13.664s-4.51001-12.98601-4.51001-21.192c0-8.232,1.5-15.29601,4.51001-21.193,3.01001-5.9223,7.13-10.4644,12.34998-13.6263,5.23999-3.1871,11.20007-4.78059,17.88-4.78059,6.65002,0,12.58008,1.5935,17.80005,4.78059,5.25,3.1619,9.38,7.704,12.39001,13.6263,3.01001,5.897,4.51001,12.961,4.51001,21.193Zm-11.51001,0c0-6.274-1.02002-11.557-3.05005-15.848-2.01001-4.316-4.77002-7.578-8.28003-9.78671-3.48999-2.2334-7.43994-3.3501-11.85999-3.3501-4.43994,0-8.41003,1.1167-11.90002,3.3501-3.47998,2.2087-6.23999,5.4707-8.27991,9.78671-2.01001,4.291-3.01001,9.574-3.01001,15.848,0,6.27299,1,11.56799,3.01001,15.884,2.03992,4.29199,4.79993,7.554,8.27991,9.787,3.48999,2.209,7.46008,3.31299,11.90002,3.31299,4.42004,0,8.37-1.10399,11.85999-3.31299,3.51001-2.233,6.27002-5.49501,8.28003-9.787,2.03003-4.31601,3.05005-9.61101,3.05005-15.884Zm26.0199,38.545V74.9091h27.47009c6,0,10.96997,1.0916,14.90991,3.2748,3.94006,2.1833,6.89001,5.1696,8.8501,8.9589,1.94995,3.7642,2.92993,8.0052,2.92993,12.7232,0,4.743-.98999,9.009-2.96997,12.798-1.95996,3.76399-4.92004,6.75099-8.88,8.959-3.93994,2.183-8.90002,3.27499-14.87,3.27499h-18.90002v-9.86199h17.83997c3.79004,0,6.87-.653,9.2301-1.958,2.34998-1.33,4.08997-3.137,5.18994-5.42001,1.09998-2.284,1.66003-4.881,1.66003-7.792s-.56006-5.49599-1.66003-7.754c-1.09998-2.259-2.84998-4.028-5.22998-5.308-2.35999-1.2799-5.46997-1.9198-9.33997-1.9198h-14.6001v67.1158h-11.63Z"
      />
      <g>
        <path
          fill="#5a595c"
          d="M29.93408,133.74463c8.0332-13.73438,24.87744-20.73145,42.49854-20.73145,33.4292,0,61.15723,24.35938,61.15723,62.97119,0,38.35254-28.24609,63.23047-61.41602,63.23047-16.84424,0-34.20654-7.51562-42.23975-21.25v8.0332c0,6.47852-5.18311,11.9209-11.92041,11.9209-6.47852,0-11.66162-5.44238-11.66162-11.9209V60.40771c0-6.47852,5.18311-11.92041,11.66162-11.92041,6.7373,0,11.92041,5.44189,11.92041,11.92041v73.33691Zm40.42578,82.40674c21.76758,0,39.12988-16.58496,39.12988-40.42676,0-24.09961-17.3623-39.64795-39.12988-39.64795-19.43555,0-40.16699,13.73438-40.16699,39.64795,0,25.65527,18.6582,40.42676,40.16699,40.42676Z"
        />
        <path
          fill="#5a595c"
          d="M160.01904,56.00244c9.06982,0,16.32568,6.47852,16.32568,15.28906,0,8.81104-7.25586,15.28955-16.32568,15.28955-8.81055,0-16.32568-6.47852-16.32568-15.28955,0-8.81055,7.51514-15.28906,16.32568-15.28906Zm11.92041,70.22705c0-6.47852-5.18262-11.92041-11.92041-11.92041-6.47852,0-11.66113,5.44189-11.66113,11.92041v99.76855c0,6.47852,5.18262,11.9209,11.66113,11.9209,6.73779,0,11.92041-5.44238,11.92041-11.9209V126.22949Z"
        />
        <path
          fill="#5a595c"
          d="M217.28516,133.74463c7.25586-14.51221,24.61816-20.73145,37.83447-20.73145,30.06006,0,46.64502,19.69482,46.64502,53.64209v59.34277c0,6.47852-5.18262,11.9209-11.92041,11.9209-6.47852,0-11.66113-5.44238-11.66113-11.9209v-57.78809c0-20.21289-10.10645-32.1333-26.69141-32.1333-17.10303,0-34.20654,9.58838-34.20654,35.24268v54.67871c0,6.47852-5.18262,11.9209-11.92041,11.9209-6.47852,0-11.66113-5.44238-11.66113-11.9209V126.22949c0-6.47852,5.18262-11.92041,11.66113-11.92041,6.73779,0,11.92041,5.44189,11.92041,11.92041v7.51514Z"
        />
        <path
          fill="#5a595c"
          d="M419.15381,60.40771c0-6.47852,5.18262-11.92041,11.92041-11.92041,6.47852,0,11.66113,5.44189,11.66113,11.92041V225.99805c0,6.47852-5.18262,11.9209-11.66113,11.9209-6.73779,0-11.92041-5.44238-11.92041-11.9209v-8.0332c-8.0332,13.73438-25.396,21.25-42.23975,21.25-33.16992,0-61.4165-24.87793-61.4165-63.23047,0-38.61182,27.72803-62.97119,61.15723-62.97119,17.62158,0,34.46582,6.99707,42.49902,20.73145V60.40771Zm-40.42578,75.66895c-21.76807,0-39.13037,15.54834-39.13037,39.64795,0,23.8418,17.3623,40.42676,39.13037,40.42676,21.5083,0,40.1665-14.77148,40.1665-40.42676,0-25.91357-20.73096-39.64795-40.1665-39.64795Z"
        />
        <path
          fill="#6AB6E3"
          d="M488.85938,284.30469c0,6.47852-5.18262,11.9209-11.92041,11.9209-6.47852,0-11.66162-5.44238-11.66162-11.9209V126.22949c0-6.47852,5.18311-11.92041,11.66162-11.92041,6.73779,0,11.92041,5.44189,11.92041,11.92041v8.29248c8.0332-13.73438,25.39551-21.50879,42.23975-21.50879,33.16992,0,60.63867,25.13672,60.63867,63.48877s-27.46875,62.71289-60.63867,62.71289c-16.84424,0-34.20654-6.73828-42.23975-20.73145v65.82129Zm39.90771-68.15332c21.76758,0,39.12988-15.29004,39.12988-39.38965s-17.3623-40.68506-39.12988-40.68506c-21.24951,0-39.64844,15.03027-39.64844,40.68506,0,25.91406,20.21289,39.38965,39.64844,39.38965Z"
        />
        <path
          fill="#6AB6E3"
          d="M630.34668,225.99805c0,6.47852-5.18262,11.9209-11.91992,11.9209-6.47852,0-11.66162-5.44238-11.66162-11.9209V60.40771c0-6.47852,5.18311-11.92041,11.66162-11.92041,6.7373,0,11.91992,5.44189,11.91992,11.92041V225.99805Z"
        />
        <path
          fill="#6AB6E3"
          d="M749.29199,217.96484c-8.0332,13.73438-25.6543,21.25-42.49902,21.25-33.16992,0-61.41602-24.87793-61.41602-63.23047,0-38.61182,28.24609-62.97119,61.41602-62.97119,16.84473,0,34.46582,6.99707,42.49902,20.73145v-7.51514c0-6.47852,5.18262-11.92041,11.66113-11.92041,6.73828,0,11.9209,5.44189,11.9209,11.92041v99.76855c0,6.47852-5.18262,11.9209-11.9209,11.9209-6.47852,0-11.66113-5.44238-11.66113-11.9209v-8.0332Zm-40.42578-81.88818c-21.76758,0-39.38867,15.54834-39.38867,39.64795,0,23.8418,17.62109,40.42676,39.38867,40.42676,21.50879,0,40.16699-14.77148,40.16699-40.42676,0-25.91357-20.73145-39.64795-40.16699-39.64795Z"
        />
        <path
          fill="#6AB6E3"
          d="M818.22363,133.74463c7.25586-14.51221,24.61816-20.73145,37.83398-20.73145,30.06055,0,46.64551,19.69482,46.64551,53.64209v59.34277c0,6.47852-5.18262,11.9209-11.9209,11.9209-6.47852,0-11.66113-5.44238-11.66113-11.9209v-57.78809c0-20.21289-10.10645-32.1333-26.69141-32.1333-17.10352,0-34.20605,9.58838-34.20605,35.24268v54.67871c0,6.47852-5.18359,11.9209-11.9209,11.9209-6.47852,0-11.66113-5.44238-11.66113-11.9209V126.22949c0-6.47852,5.18262-11.92041,11.66113-11.92041,6.7373,0,11.9209,5.44189,11.9209,11.92041v7.51514Z"
        />
        <path
          fill="#6AB6E3"
          d="M1029.16016,185.05371h-89.14355c2.85059,20.21387,18.6582,32.91113,39.12988,32.91113,14.51172,0,22.54492-3.62793,30.83789-12.69727,4.40527-4.92383,11.14258-6.47852,15.80762-3.11035,4.66406,3.62793,5.70117,10.625,2.07324,14.77148-11.66113,15.03027-29.80176,22.28613-49.49609,22.28613-34.46582,0-61.93457-25.13672-61.93457-63.49023s27.46875-62.71143,61.93457-62.71143,60.12012,24.35938,60.12012,62.71143c0,5.44238-4.40527,9.3291-9.3291,9.3291Zm-13.99316-18.39844c-2.85059-20.21289-17.3623-32.39258-36.79785-32.39258-19.9541,0-35.50195,12.17969-38.35254,32.39258h75.15039Z"
        />
      </g>
    </svg>
  );
};

// 2023 updated logo
export const BindPlaneLogo: React.FC<React.ComponentProps<"svg">> = (props) => {
  return (
    <svg
      width="87"
      height="26"
      viewBox="0 0 87 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.35286 9.51986C4.87617 9.51986 3.61904 10.0694 2.91743 11.0052V5.2721C2.91743 4.675 2.42878 4.18945 1.82787 4.18945C1.22696 4.18945 0.759766 4.675 0.759766 5.2721V18.9529C0.759766 19.55 1.23934 20.0355 1.82787 20.0355C2.4164 20.0355 2.91743 19.55 2.91743 18.9529V18.6133C3.624 19.5516 4.92404 20.143 6.33057 20.143C9.29798 20.143 11.5357 17.8546 11.5357 14.8207C11.5357 11.7869 9.35576 9.52068 6.35203 9.52068L6.35286 9.51986ZM9.33513 14.7986C9.33513 16.6473 7.97895 18.0408 6.18034 18.0408C4.61946 18.0408 2.93889 17.0262 2.93889 14.7986C2.93889 12.735 4.60873 11.6212 6.18034 11.6212C8.00784 11.6212 9.33513 12.9573 9.33513 14.7986Z"
        fill={colors.black}
      />
      <path
        d="M13.6345 9.62695C13.046 9.62695 12.5664 10.1125 12.5664 10.7096V18.9525C12.5664 19.5496 13.046 20.0352 13.6345 20.0352C14.223 20.0352 14.7241 19.5496 14.7241 18.9525V10.7096C14.7241 10.1125 14.2354 9.62695 13.6345 9.62695Z"
        fill={colors.black}
      />
      <path
        d="M13.6338 4.80859C12.8174 4.80859 12.1777 5.40651 12.1777 6.17011C12.1777 6.9337 12.8174 7.5308 13.6338 7.5308C14.4501 7.5308 15.0898 6.93288 15.0898 6.17011C15.0898 5.40733 14.4501 4.80859 13.6338 4.80859Z"
        fill={colors.black}
      />
      <path
        d="M21.5403 9.51953C20.4788 9.51953 19.1812 9.97146 18.4936 10.9713V10.7096C18.4936 10.1125 18.005 9.62698 17.404 9.62698C16.8031 9.62698 16.3359 10.1125 16.3359 10.7096V18.9525C16.3359 19.5496 16.8155 20.0352 17.404 20.0352C17.9926 20.0352 18.4936 19.5496 18.4936 18.9525V14.4349C18.4936 11.8243 20.5951 11.6217 21.239 11.6217C22.5671 11.6217 23.3595 12.5772 23.3595 14.1782V18.9525C23.3595 19.5496 23.8391 20.0352 24.4284 20.0352C25.0178 20.0352 25.518 19.5496 25.518 18.9525V14.0494C25.518 11.1706 24.0685 9.51953 21.5411 9.51953H21.5403Z"
        fill={colors.black}
      />
      <path
        d="M36.1696 4.18945C35.5687 4.18945 35.0792 4.675 35.0792 5.2721V11.0052C34.3784 10.0694 33.1213 9.51986 31.6446 9.51986C28.6409 9.51986 26.4609 11.7491 26.4609 14.8199C26.4609 17.8907 28.6987 20.1421 31.6661 20.1421C33.0726 20.1421 34.3735 19.5508 35.0792 18.6133V18.9529C35.0792 19.55 35.5679 20.0355 36.1696 20.0355C36.7713 20.0355 37.2377 19.55 37.2377 18.9529V5.2721C37.2377 4.675 36.759 4.18945 36.1696 4.18945ZM35.0586 14.7994C35.0586 17.0271 33.3788 18.0416 31.8171 18.0416C30.0185 18.0416 28.6624 16.6481 28.6624 14.7994C28.6624 12.9507 29.9888 11.622 31.8171 11.622C33.3879 11.622 35.0586 12.7358 35.0586 14.7994Z"
        fill={colors.black}
      />
      <path
        d="M44.4868 9.51953C43.0861 9.51953 41.7852 10.1183 41.0737 11.0689V10.7088C41.0737 10.1117 40.585 9.62616 39.9841 9.62616C39.3832 9.62616 38.916 10.1117 38.916 10.7088V23.7687C38.916 24.3658 39.3948 24.8513 39.9841 24.8513C40.5735 24.8513 41.0737 24.3658 41.0737 23.7687V18.6589C41.7637 19.5808 43.0374 20.1418 44.4868 20.1418C47.4658 20.1418 49.6276 17.9216 49.6276 14.8622C49.6276 11.8029 47.4658 9.51953 44.4868 9.51953ZM47.4476 14.8844C47.4476 16.7429 46.1501 18.0405 44.2928 18.0405C42.7039 18.0405 41.0951 16.9562 41.0951 14.8844C41.0951 12.6428 42.7526 11.6209 44.2928 11.6209C46.1212 11.6209 47.4476 12.993 47.4476 14.8844Z"
        fill={colors.black}
      />
      <path
        d="M51.7478 4.18945C51.1593 4.18945 50.6797 4.675 50.6797 5.2721V18.9529C50.6797 19.55 51.1593 20.0355 51.7478 20.0355C52.3363 20.0355 52.8374 19.55 52.8374 18.9529V5.2721C52.8374 4.675 52.3487 4.18945 51.7478 4.18945Z"
        fill={colors.black}
      />
      <path
        d="M63.3825 9.62698C62.7931 9.62698 62.3136 10.1125 62.3136 10.7096V11.0082C61.6169 10.0953 60.3094 9.51953 58.879 9.51953C55.8629 9.51953 53.6738 11.7488 53.6738 14.8196C53.6738 17.8904 55.9116 20.1418 58.879 20.1418C60.2979 20.1418 61.6062 19.5504 62.3136 18.6121V18.9517C62.3136 19.5488 62.7931 20.0344 63.3825 20.0344C63.9719 20.0344 64.4721 19.5488 64.4721 18.9517V10.7088C64.4721 10.1117 63.9834 9.62616 63.3825 9.62616V9.62698ZM62.2921 14.7991C62.2921 17.0267 60.6124 18.0413 59.0515 18.0413C57.2405 18.0413 55.8752 16.6478 55.8752 14.7991C55.8752 12.9504 57.2108 11.6217 59.0515 11.6217C60.6223 11.6217 62.2921 12.7355 62.2921 14.7991Z"
        fill={colors.black}
      />
      <path
        d="M71.0735 9.51953C70.012 9.51953 68.7144 9.97146 68.0268 10.9713V10.7096C68.0268 10.1125 67.5382 9.62698 66.9372 9.62698C66.3363 9.62698 65.8691 10.1125 65.8691 10.7096V18.9525C65.8691 19.5496 66.3487 20.0352 66.9372 20.0352C67.5258 20.0352 68.0268 19.5496 68.0268 18.9525V14.4349C68.0268 11.8243 70.1283 11.6217 70.7722 11.6217C72.0995 11.6217 72.8927 12.5772 72.8927 14.1782V18.9525C72.8927 19.5496 73.3723 20.0352 73.9616 20.0352C74.551 20.0352 75.0512 19.5496 75.0512 18.9525V14.0494C75.0512 11.1706 73.6017 9.51953 71.0743 9.51953H71.0735Z"
        fill={colors.black}
      />
      <path
        d="M86.34 14.7983C86.34 11.6906 84.2443 9.51953 81.243 9.51953C78.2418 9.51953 75.9941 11.7398 75.9941 14.7983C75.9941 17.8568 78.2013 20.1418 81.243 20.1418C83.0004 20.1418 84.4903 19.4742 85.4329 18.2668C85.5864 18.0921 85.6582 17.8584 85.6343 17.6082C85.6079 17.331 85.4667 17.0743 85.2447 16.9021C84.827 16.6027 84.2063 16.72 83.7994 17.1727C83.1085 17.924 82.4564 18.1898 81.3082 18.1898C79.6582 18.1898 78.4382 17.2039 78.17 15.6669H85.4667C85.9488 15.6669 86.3409 15.2773 86.3409 14.7974L86.34 14.7983ZM84.1873 13.951H78.1691C78.4333 12.4402 79.6277 11.4708 81.243 11.4708C82.8584 11.4708 83.924 12.4181 84.1873 13.951Z"
        fill={colors.black}
      />
    </svg>
  );
};
