import { gql } from "@apollo/client";
import { withRBAC } from "../../contexts/RBAC";
import { useGetAuditEventsQuery } from "../../graphql/generated";
import {
  DataGrid,
  DataGridProps,
  GridSortingInitialState,
} from "@mui/x-data-grid";
import { Stack } from "@mui/material";
import { isEmpty } from "lodash";
import { useState } from "react";
import { AuditLogsOptions } from "./AuditLogsOptions";
import { addOneDay } from "../../utils/time";
import { withEENavBar } from "../../components/EENavBar";
import { withRequireLogin } from "../../contexts/RequireLogin";

import styles from "./audit-logs-page.module.scss";

gql`
  query GetAuditEvents($options: AuditEventFilter, $sort: String) {
    auditEvents(options: $options, sort: $sort) {
      id
      timestamp
      resourceName
      resourceKind
      configuration
      action
      user
      account
      description
    }
  }
`;

const INIT_SORT: GridSortingInitialState = {
  sortModel: [{ field: "timestamp", sort: "desc" }],
};

export const AuditLogsPageContent: React.FC = () => {
  const [selectedConfig, setSelectedConfig] = useState<string | null>(null);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [minDate, setMinDate] = useState<Date | null>(null);
  const [maxDate, setMaxDate] = useState<Date | null>(null);

  const { data, loading } = useGetAuditEventsQuery({
    fetchPolicy: "network-only",
    variables: {
      options: {
        configuration: selectedConfig ?? undefined,
        user: selectedUser ?? undefined,
        minDate: minDate?.toISOString(),
        // Add 1 day to maxDate to include the entire day.
        maxDate: addOneDay(maxDate)?.toISOString(),
      },
    },
  });

  const columns: DataGridProps["columns"] = [
    {
      field: "timestamp",
      width: 180,
      headerName: "Date",
      renderCell({ value }) {
        return new Date(value).toLocaleString();
      },
      valueGetter: ({ value }) => new Date(value).getTime(),
    },
    {
      field: "configuration",
      flex: 1,
      renderCell({ value }) {
        return isEmpty(value) ? "N/A" : value;
      },
      headerName: "Configuration",
    },
    { field: "resourceKind", width: 120, headerName: "Resource" },
    { field: "action", width: 120, headerName: "Action" },
    { field: "resourceName", flex: 1, headerName: "Name" },
    { field: "user", flex: 1, headerName: "User" },
    { field: "description", flex: 2, headerName: "Description" },
  ];

  return (
    <div className={styles.container} style={{ height: "calc(100vh - 200px)" }}>
      <AuditLogsOptions
        selectedConfig={selectedConfig}
        setSelectedConfig={setSelectedConfig}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        minDate={minDate}
        setMinDate={setMinDate}
        maxDate={maxDate}
        setMaxDate={setMaxDate}
      />
      <DataGrid
        columns={columns}
        rows={data?.auditEvents ?? []}
        loading={loading}
        disableRowSelectionOnClick
        slots={{
          noRowsOverlay: NoRowsOverlay,
        }}
        // This is a workaround for component testing.
        autoHeight={process.env.NODE_ENV === "test"}
        initialState={{
          sorting: INIT_SORT,
        }}
      />
    </div>
  );
};

const NoRowsOverlay: React.FC = () => {
  return (
    <Stack
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      No Audit Logs
    </Stack>
  );
};

export const AuditLogsPage = withRequireLogin(
  withRBAC(withEENavBar(AuditLogsPageContent))
);
