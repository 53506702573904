import { Card, Chip, Stack, TableRow, Typography } from "@mui/material";
import { useMemo } from "react";
import { Metric, PipelineType } from "../../../graphql/generated";
import { getTimestamp } from "../utils";
import { CellLabel, CellValue } from "./Cells";
import { DetailsContainer } from "./DetailsContainer";
import { MapValueSummary } from "./MapValueSummary";
import { RowSummary } from "./RowSummary";
import { SummaryTable } from "./SummaryTable";
import { AttributesProvider } from "./SnapShotRow";
import { SnapshotRegion } from "../regions";

import styles from "../snap-shot-console.module.scss";

interface MetricsRecordRowProps {
  message: Metric;
  attributes: AttributesProvider;
  bindplaneID: string;
}

export const MetricsRecordRow: React.FC<MetricsRecordRowProps> = ({
  message,
  attributes,
  bindplaneID,
}) => {
  const timestamp = useMemo(
    () => getTimestamp(message, PipelineType.Logs),
    [message]
  );

  // value could be an object for type Summary metrics
  const stringifiedValue = JSON.stringify(message.value);

  return (
    <Card
      classes={{ root: styles.card }}
      data-region={SnapshotRegion.ROW}
      data-row-id={bindplaneID}
    >
      <RowSummary
        bindplaneID={bindplaneID}
        timestamp={timestamp}
        data-region={SnapshotRegion.ROW_SUMMARY}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Chip
            className={styles["metric-name"]}
            label={message.name}
            size={"small"}
            data-region={SnapshotRegion.ROW_NAME}
            data-name={message.name}
          />

          <Typography
            fontFamily="monospace"
            fontSize={12}
            overflow={"hidden"}
            textOverflow="ellipsis"
          >
            {stringifiedValue} {message.unit}
          </Typography>
        </Stack>
      </RowSummary>

      <DetailsContainer>
        <Typography fontWeight={600}>Metric</Typography>
        <SummaryTable>
          <TableRow>
            <CellLabel>timestamp</CellLabel>
            <CellValue>{timestamp}</CellValue>
          </TableRow>
          <TableRow>
            <CellLabel>name</CellLabel>
            <CellValue>{message.name}</CellValue>
          </TableRow>
          <TableRow>
            <CellLabel>value</CellLabel>
            <CellValue>{stringifiedValue}</CellValue>
          </TableRow>
          <TableRow>
            <CellLabel>type</CellLabel>
            <CellValue>{message.type}</CellValue>
          </TableRow>
          <TableRow>
            <CellLabel>unit</CellLabel>
            <CellValue>{message.unit}</CellValue>
          </TableRow>
        </SummaryTable>

        <Typography fontWeight={600} marginTop={2}>
          Attributes
        </Typography>
        <MapValueSummary
          value={attributes()}
          fieldType="attribute"
          emptyMessage="No attribute values"
        />

        <Typography fontWeight={600} marginTop={2}>
          Resource
        </Typography>
        <MapValueSummary
          value={message.resource}
          fieldType="resource"
          emptyMessage="No resource values"
        />
      </DetailsContainer>
    </Card>
  );
};
