import { Button, Stack, Typography, Box } from "@mui/material";
import React from "react";
import {
  GetAgentAndConfigurationsQuery,
  Role,
} from "../../../graphql/generated";
import { AgentStatus } from "../../../types/agents";
import {
  renderAgentDate,
  renderAgentLabels,
  renderAgentStatus,
} from "../utils";
import { ArrowUpIcon } from "../../Icons";
import { upgradeAgent } from "../../../utils/rest/upgrade-agent";
import { isEmpty } from "lodash";
import { RBACWrapper } from "../../RBACWrapper/RBACWrapper";
import { getAgentPlatformIcon } from "../../../utils/platform-display-data";

import styles from "./agent-table.module.scss";

type AgentTableAgent = NonNullable<GetAgentAndConfigurationsQuery["agent"]>;
interface AgentTableProps {
  agent: AgentTableAgent;
}

export const AgentTable: React.FC<AgentTableProps> = ({ agent }) => {
  function renderTable(agent: AgentTableAgent): JSX.Element {
    const { status, labels, connectedAt, disconnectedAt } = agent;

    const labelsEl = renderAgentLabels(labels);

    function renderConnectedAtRow(): JSX.Element {
      if (status === AgentStatus.CONNECTED) {
        const connectedEl = renderAgentDate(connectedAt);
        return renderRow("Connected", connectedEl);
      }

      const disconnectedEl = renderAgentDate(disconnectedAt);
      return renderRow("Disconnected", disconnectedEl);
    }

    function fillIn(s: any): string {
      return isEmpty(s) ? "-" : s;
    }

    return (
      <Stack direction={"row"}>
        <Stack width={"35rem"}>
          {renderRow(
            "Name",
            <>
              <Stack direction={"row"} alignItems={"center"}>
                {agent.platform && getAgentPlatformIcon(agent.platform) && (
                  <Box className={styles["platform-icon"]}>
                    {getAgentPlatformIcon(agent.platform)}
                  </Box>
                )}

                {fillIn(agent.name)}
                <Stack
                  className="status-chip"
                  display={"inline"}
                  style={{
                    position: "relative",
                    marginLeft: "0.5rem",
                    top: "-0.1rem",
                  }}
                >
                  {renderAgentStatus(agent.status)}
                </Stack>
              </Stack>
            </>
          )}
          {renderVersionRow("Version", agent)}
          {renderRow("Host Name", <>{fillIn(agent.hostName)}</>)}
          {renderRow(
            "Platform",
            <>{fillIn(`${agent.platform} ${agent.architecture}`)}</>
          )}
          {renderRow("Operating System", <>{fillIn(agent.operatingSystem)}</>)}
          {renderConnectedAtRow()}
        </Stack>
        <Stack>
          {renderLabelsRow("Labels", labelsEl)}
          {renderRow("Agent ID", <>{fillIn(agent.id)}</>)}
          {renderRow("Remote Address", <>{fillIn(agent.remoteAddress)}</>)}
          {renderRow("MAC Address", <>{fillIn(agent.macAddress)}</>)}
          {renderRow("Home", <>{fillIn(agent.home)}</>)}
        </Stack>
      </Stack>
    );
  }
  return <>{agent == null ? null : renderTable(agent)}</>;
};

function renderVersionRow(key: string, agent: AgentTableAgent): JSX.Element {
  async function handleUpgrade() {
    if (!agent.upgradeAvailable) {
      return;
    }

    try {
      await upgradeAgent(agent.id, agent.upgradeAvailable);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      <Stack direction={"row"}>
        <Typography classes={{ root: styles["key-column"] }}>{key}</Typography>
        <Typography>{agent.version}</Typography>
        {agent.upgradeAvailable &&
          agent.status !== AgentStatus.DISCONNECTED && (
            <RBACWrapper requiredRole={Role.User}>
              <Button
                endIcon={<ArrowUpIcon width={"20px"} />}
                size="small"
                classes={{ root: styles["upgrade-button"] }}
                disabled={agent.status === AgentStatus.UPGRADING}
                onClick={() => handleUpgrade()}
              >
                Upgrade to {agent.upgradeAvailable}
              </Button>
            </RBACWrapper>
          )}
      </Stack>
    </>
  );
}

function renderRow(key: string, value: JSX.Element): JSX.Element {
  return (
    <Stack direction={"row"}>
      <Typography classes={{ root: styles["key-column"] }}>{key}</Typography>
      <Typography>{value}</Typography>
    </Stack>
  );
}

function renderLabelsRow(key: string, value: JSX.Element): JSX.Element {
  return (
    <Stack direction={"row"}>
      <Typography classes={{ root: styles["key-column"] }}>{key}</Typography>
      <Stack direction="row">{value}</Stack>
    </Stack>
  );
}
