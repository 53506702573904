import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { InvitationStatus } from "../../graphql/generated";
import { hasPermission, useRole } from "../../contexts/RBAC";
import { Role } from "../../graphql/generated";

import {
  CopyIcon,
  MoreVertical,
  TrashIcon,
} from "../Icons";

import styles from "./invitations-table.module.scss";

interface InvitationOptionsProps {
  id: string;
  status: InvitationStatus;
  onDelete: (id: string) => void;
  link: string;
}

export const InvitationOptions: React.FC<InvitationOptionsProps> = ({
  id,
  link,
  status,
  onDelete,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const role = useRole();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { enqueueSnackbar } = useSnackbar();
  const handleCopyLink = () => {
    enqueueSnackbar("Invitation link copied to clipboard", {
      variant: "success",
      key: "copy-invitation-link",
    });
    handleClose();
  };

  const handleDeleteClick = () => {
    handleClose();
    onDelete(id);
  };

  if (!hasPermission(Role.Admin, role)) {
    return null;
  }

  return (
    <>
      <IconButton onClick={handleClick} aria-label="options">
        <MoreVertical />
      </IconButton>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem onClick={handleDeleteClick}>
          <ListItemIcon>
            <TrashIcon width={20} className={styles.error} />
          </ListItemIcon>
          <ListItemText className={styles.error}>
            <Typography color="error">Delete</Typography>
          </ListItemText>
        </MenuItem>
        {status === InvitationStatus.Outstanding && (
          <CopyToClipboard text={link}>
            <MenuItem onClick={handleCopyLink}>
              <ListItemIcon>
                <CopyIcon width={20} />
              </ListItemIcon>
              <ListItemText>
                <Typography>Copy Invitation Link</Typography>
              </ListItemText>
            </MenuItem>
          </CopyToClipboard>
        )}
      </Menu>
    </>
  );
};
