import { gql } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import { useGetAgentMonitoringSummaryQuery } from "../../graphql/generated";
import { NumberChip } from "../NumberChip";
import { useLocation, useNavigate } from "react-router-dom";
import { AGENTS_PAGE_QUERY_PARAM } from "../../pages/agents";
import colors from "../../styles/colors";

gql`
  query GetAgentMonitoringSummary {
    agentMonitoring {
      total
      disconnected
      errored
      outdated
      connected
    }
  }
`;

/**
 * AgentMonitoring shows the total, connected, disconnected, outdated, and errored
 * agent counts in a flex wrapped row.
 */
export const AgentMonitoring: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { data, loading } = useGetAgentMonitoringSummaryQuery({
    onError(err) {
      console.error(err);
    },
    fetchPolicy: "network-only",
    pollInterval: 5000,
  });

  function onTotalClick() {
    navigate("/agents");
  }

  function onConnectedClick() {
    const params = new URLSearchParams(location.search);
    params.set(AGENTS_PAGE_QUERY_PARAM, "status:Connected");
    navigate(`/agents?${params.toString()}`);
  }

  function onDisconnectedClick() {
    const params = new URLSearchParams(location.search);
    params.set(AGENTS_PAGE_QUERY_PARAM, "status:Disconnected");
    navigate(`/agents?${params.toString()}`);
  }

  function onOutdatedClick() {
    const params = new URLSearchParams(location.search);
    params.set(AGENTS_PAGE_QUERY_PARAM, "-version:latest");
    navigate(`/agents?${params.toString()}`);
  }

  function onErroredClick() {
    const params = new URLSearchParams(location.search);
    params.set(AGENTS_PAGE_QUERY_PARAM, "status:Error");
    navigate(`/agents?${params.toString()}`);
  }

  return (
    <div>
      <Typography fontWeight={600} fontSize={18} marginBottom="8px">
        Agents
      </Typography>

      <Stack direction="row" spacing={3}>
        <NumberChip
          loading={loading}
          label="Total"
          value={data?.agentMonitoring?.total}
          onClick={onTotalClick}
        />
        <NumberChip
          loading={loading}
          label="Connected"
          value={data?.agentMonitoring?.connected}
          color="green"
          onClick={onConnectedClick}
        />
        <NumberChip
          loading={loading}
          label="Disconnected"
          value={data?.agentMonitoring?.disconnected}
          color="slategray"
          onClick={onDisconnectedClick}
        />
      </Stack>
      <Stack direction="row" spacing={3}>
        <NumberChip
          loading={loading}
          label="Outdated"
          value={data?.agentMonitoring?.outdated}
          color={colors.neonCarrot}
          onClick={onOutdatedClick}
        />
        <NumberChip
          loading={loading}
          label="Errored"
          value={data?.agentMonitoring?.errored}
          color="error"
          onClick={onErroredClick}
        />
      </Stack>
    </div>
  );
};
