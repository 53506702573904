import { Chip } from "@mui/material";
import {
  GridCellParams,
  GridColDef,
  GridValueGetterParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { ComponentProps } from "react";
import { AgentStatus } from "../../types/agents";
import { formatMetric } from "../../utils/graph/utils";
import {
  TELEMETRY_TYPES,
  TELEMETRY_SIZE_METRICS,
  DEFAULT_AGENTS_TABLE_PERIOD,
} from "../MeasurementControlBar/MeasurementControlBar";
import { SearchLink } from "../SearchLink";
import { TableAgent, AgentMetrics, AgentsTableField } from "./types";
import { trimVersion, resourceVersion } from "../../utils/version-helpers";
import { AGENT_LABEL_INSTALL_ID } from "../../pages/agents/InstallPage/InstallWizard/utils";

import mixins from "../../styles/mixins.module.scss";

function renderConfigurationCell(cellParams: GridCellParams<any, string>) {
  const configName = cellParams.value;
  if (configName == null) {
    return <></>;
  }
  return (
    <SearchLink
      path={`/configurations/${configName}`}
      displayName={configName}
    />
  );
}

function renderNameDataCell(
  cellParams: GridCellParams<{ name: string; id: string }, TableAgent>
): JSX.Element {
  return (
    <SearchLink
      path={`/agents/${cellParams.row.id}`}
      displayName={cellParams.row.name}
    />
  );
}

function renderLabelDataCell(
  cellParams: GridCellParams<any, Record<string, string>>
): JSX.Element {
  return renderAgentLabels(cellParams.value);
}

function renderStatusDataCell(
  cellParams: GridCellParams<any, AgentStatus>
): JSX.Element {
  return renderAgentStatus(cellParams.value);
}

function createMetricRateColumn(
  field: string,
  telemetryType: string,
  width: number,
  agentMetrics?: AgentMetrics
): GridColDef[][0] {
  return {
    field,
    width: width,
    headerName: TELEMETRY_TYPES[telemetryType],
    valueGetter: (params: GridValueGetterParams) => {
      if (agentMetrics == null) {
        return "";
      }
      // should probably have a lookup table here rather than interpolate in two places
      const metricName = TELEMETRY_SIZE_METRICS[telemetryType];
      const agentName = params.id;

      // get all metrics for this agent that match the pattern /^destination\/\w+$/
      // those are metrics for data received by a destination, ignoring values before the processors
      const metrics = agentMetrics.filter(
        (m) =>
          m.name === metricName &&
          m.agentID! === agentName &&
          m.nodeID.startsWith("destination/") &&
          !m.nodeID.endsWith("/processors")
      );
      if (metrics == null) {
        return 0;
      }
      // to make this sortable, we use the raw value and provide a valueFormatter implementation to show units
      return metrics.reduce((a, b) => a + b.value, 0);
    },
    valueFormatter: (params: GridValueFormatterParams<number>): string => {
      if (params.value === 0) {
        return "";
      }

      const metricName = TELEMETRY_SIZE_METRICS[telemetryType];
      const agentName = params.id;

      const metrics = agentMetrics?.find(
        (m) => m.name === metricName && m.agentID! === agentName
      );
      return formatMetric(
        { value: params.value, unit: metrics?.unit || "B/s" },
        DEFAULT_AGENTS_TABLE_PERIOD
      );
    },
  };
}

function renderAgentLabels(
  labels: Record<string, string> | undefined
): JSX.Element {
  if (labels == null) return <></>;

  if (labels[AGENT_LABEL_INSTALL_ID]) {
    delete labels[AGENT_LABEL_INSTALL_ID];
  }

  return (
    <>
      {Object.entries(labels).map(([k, v]) => {
        if (k.startsWith("bindplane/agent")) return null;

        const formattedLabel = `${k}: ${v}`;
        return (
          <Chip
            key={k}
            size="small"
            label={formattedLabel}
            classes={{ root: mixins["m-1"] }}
          />
        );
      })}
    </>
  );
}

function renderAgentStatus(status: AgentStatus | undefined): JSX.Element {
  let statusText: string;
  let color: ComponentProps<typeof Chip>["color"] = "default";

  switch (status) {
    case AgentStatus.DISCONNECTED:
      statusText = "Disconnected";
      break;
    case AgentStatus.CONNECTED:
      statusText = "Connected";
      color = "success";
      break;
    case AgentStatus.ERROR:
      statusText = "Errored";
      color = "error";
      break;
    // Component failed is deprecated.
    case AgentStatus.COMPONENT_FAILED:
      statusText = "Component Failed";
      break;
    case AgentStatus.DELETED:
      statusText = "Deleted";
      break;
    case AgentStatus.CONFIGURING:
      statusText = "Configuring";
      break;
    case AgentStatus.UPGRADING:
      statusText = "Upgrading";
      color = "warning";
      break;
    default:
      statusText = "";
      break;
  }

  return <Chip size="small" color={color} label={statusText} />;
}

export function makeAgentsTableColumns(
  fields: AgentsTableField[],
  agentMetrics: AgentMetrics
): GridColDef[] {
  return fields.map((field) => {
    switch (field) {
      case AgentsTableField.STATUS:
        return {
          field: AgentsTableField.STATUS,
          headerName: "Status",
          width: 150,
          renderCell: renderStatusDataCell,
        };
      case AgentsTableField.VERSION:
        return {
          field: AgentsTableField.VERSION,
          headerName: "Version",
          width: 100,
        };
      case AgentsTableField.CONFIGURATION:
        return {
          field: AgentsTableField.CONFIGURATION,
          headerName: "Configuration",
          width: 200,
          renderCell: renderConfigurationCell,
          valueGetter: (params: GridValueGetterParams<TableAgent>) => {
            const configuration = params.row.configurationStatus.current;
            return trimVersion(configuration);
          },
        };
      case AgentsTableField.OPERATING_SYSTEM:
        return {
          field: AgentsTableField.OPERATING_SYSTEM,
          headerName: "Operating System",
          width: 200,
        };
      case AgentsTableField.LABELS:
        return {
          sortable: false,
          field: AgentsTableField.LABELS,
          headerName: "Labels",
          width: 200,
          renderCell: renderLabelDataCell,
          valueGetter: (params: GridValueGetterParams<TableAgent>) => {
            return params.row.labels;
          },
        };
      case AgentsTableField.LOGS:
        return createMetricRateColumn(field, "logs", 100, agentMetrics);
      case AgentsTableField.METRICS:
        return createMetricRateColumn(field, "metrics", 100, agentMetrics);
      case AgentsTableField.TRACES:
        return createMetricRateColumn(field, "traces", 100, agentMetrics);
      case AgentsTableField.CONFIGURATION_VERSION:
        return {
          sortable: true,
          field: AgentsTableField.CONFIGURATION_VERSION,
          headerName: "Configuration Version",
          width: 200,
          valueGetter: (params: GridValueGetterParams<TableAgent>) => {
            const configurationCurrent = params.row.configurationStatus.current;
            const configurationVersion = resourceVersion(configurationCurrent);
            if (configurationVersion === "") {
              return "-";
            }

            const configurationName = trimVersion(configurationCurrent);
            const configurationLabel = params.row.labels?.configuration;

            const matches = configurationName === configurationLabel;
            return matches ? configurationVersion : "-";
          },
        };
      default:
        return {
          field: AgentsTableField.NAME,
          headerName: "Name",
          valueGetter: (params: GridValueGetterParams<TableAgent>) => {
            return params.row.name;
          },
          renderCell: renderNameDataCell,
          width: 240,
        };
    }
  });
}
