import { gql } from "@apollo/client";
import { Dialog, DialogProps, Grid, Stack } from "@mui/material";
import { isEqual } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import {
  GetResourceTypesQuery,
  Kind,
  PipelineType,
  ResourceConfiguration,
  ResourceTypeKind,
  Role,
  useProcessorDialogDestinationTypeLazyQuery,
  useProcessorDialogSourceTypeLazyQuery,
  useUpdateProcessorsMutation,
} from "../../graphql/generated";
import { trimVersion } from "../../utils/version-helpers";
import { usePipelineGraph } from "../PipelineGraph/PipelineGraphContext";
import {} from "../ResourceConfigForm";
import { SnapshotConsole } from "../SnapShotConsole/SnapShotConsole";
import { ResourceDialogContextProvider } from "../ResourceDialog/ResourceDialogContext";
import {
  SnapshotContextProvider,
  useSnapshot,
} from "../SnapShotConsole/SnapshotContext";
import { hasPermission } from "../../utils/has-permission";
import { useRole } from "../../hooks/useRole";
import { TitleSection } from "../DialogComponents";
import { ResourceConfigurationEditor } from "../ResourceConfigurationEditor";

import styles from "./processor-dialog.module.scss";
import mixins from "../../styles/mixins.module.scss";

interface ProcessorDialogProps extends DialogProps {
  processors: ResourceConfiguration[];
  readOnly?: boolean;
}

gql`
  query processorDialogSourceType($name: String!) {
    sourceType(name: $name) {
      metadata {
        name
        id
        version
        displayName
        description
      }
      spec {
        telemetryTypes
      }
    }
  }

  query processorDialogDestinationType($name: String!) {
    destinationWithType(name: $name) {
      destinationType {
        metadata {
          id
          name
          version
          displayName
          description
        }
        spec {
          telemetryTypes
        }
      }
    }
  }

  mutation updateProcessors($input: UpdateProcessorsInput!) {
    updateProcessors(input: $input)
  }
`;

export type ProcessorType = GetResourceTypesQuery["resourceTypes"][0];

export const ProcessorDialog: React.FC = () => {
  const role = useRole();
  const {
    editProcessorsInfo,
    configuration,
    editProcessorsOpen,
    closeProcessorDialog,
    readOnlyGraph,
  } = usePipelineGraph();

  if (editProcessorsInfo === null) {
    return null;
  }

  let processors: ResourceConfiguration[] = [];
  switch (editProcessorsInfo?.resourceType) {
    case "source":
      processors =
        configuration?.spec?.sources?.[editProcessorsInfo.index].processors ??
        [];
      break;
    case "destination":
      processors =
        configuration?.spec?.destinations?.[editProcessorsInfo.index]
          .processors ?? [];
      break;
    default:
      processors = [];
  }

  return (
    <ProcessorDialogComponent
      open={editProcessorsOpen}
      onClose={closeProcessorDialog}
      processors={processors}
      readOnly={readOnlyGraph || !hasPermission(Role.User, role)}
    />
  );
};

export const ProcessorDialogComponent: React.FC<ProcessorDialogProps> = ({
  processors: processorsProp,
  readOnly,
  ...dialogProps
}) => {
  const {
    editProcessorsInfo,
    configuration,
    closeProcessorDialog,
    refetchConfiguration,
    selectedTelemetryType,
  } = usePipelineGraph();

  const [processors, setProcessors] = useState(processorsProp);
  const { enqueueSnackbar } = useSnackbar();

  // Get the type of the source or destination to which we're adding processors
  const resourceTypeName = useMemo(() => {
    try {
      switch (editProcessorsInfo?.resourceType) {
        case "source":
          return configuration?.spec?.sources?.[editProcessorsInfo.index].type;
        case "destination":
          return configuration?.spec?.destinations?.[editProcessorsInfo.index]
            .type;
        default:
          return null;
      }
    } catch (err) {
      return null;
    }
  }, [
    configuration?.spec?.destinations,
    configuration?.spec?.sources,
    editProcessorsInfo?.index,
    editProcessorsInfo?.resourceType,
  ]);

  /* ------------------------ GQL Mutations and Queries ----------------------- */
  const [updateProcessors] = useUpdateProcessorsMutation({});

  const [fetchSourceType, { data: sourceTypeData }] =
    useProcessorDialogSourceTypeLazyQuery({
      variables: { name: resourceTypeName ?? "" },
    });
  const [fetchDestinationType, { data: destinationTypeData }] =
    useProcessorDialogDestinationTypeLazyQuery();

  useEffect(() => {
    function fetchData() {
      if (editProcessorsInfo!.resourceType === "source") {
        fetchSourceType({ variables: { name: resourceTypeName ?? "" } });
      } else {
        const destName =
          configuration?.spec?.destinations?.[editProcessorsInfo!.index].name;
        fetchDestinationType({ variables: { name: destName ?? "" } });
      }
    }

    if (editProcessorsInfo == null) {
      return;
    }

    fetchData();
  }, [
    configuration?.spec?.destinations,
    editProcessorsInfo,
    fetchDestinationType,
    fetchSourceType,
    resourceTypeName,
  ]);

  /* -------------------------------- Functions ------------------------------- */

  // handleClose is called when a user clicks off the dialog or the "X" button
  function handleClose() {
    if (!isEqual(processors, processorsProp)) {
      const ok = window.confirm("Discard changes?");
      if (!ok) {
        return;
      }
      // reset form values if chooses to discard.
      setProcessors(processorsProp);
    }

    closeProcessorDialog();
  }

  async function handleUpdateInlineProcessors(
    processors: ResourceConfiguration[]
  ): Promise<boolean> {
    let success = true;
    await updateProcessors({
      variables: {
        input: {
          configuration: configuration?.metadata?.name!,
          resourceType:
            editProcessorsInfo?.resourceType === "source"
              ? ResourceTypeKind.Source
              : ResourceTypeKind.Destination,
          resourceIndex: editProcessorsInfo?.index!,
          processors: processors,
        },
      },
      onError(error) {
        success = false;
        console.error(error);
        enqueueSnackbar("Failed to save processors", {
          variant: "error",
          key: "save-processors-error",
        });
      },
    });

    return success;
  }

  // displayName for sources is the sourceType name, for destinations it's the destination name
  const { resourceName, displayName } = useMemo(() => {
    if (editProcessorsInfo == null) {
      return {
        resourceName: "",
        displayName: "",
      };
    }
    if (editProcessorsInfo.resourceType === "source") {
      const id = configuration?.spec?.sources?.[editProcessorsInfo.index].id;
      const resourceName = `source${editProcessorsInfo.index}_${id}`;
      return {
        resourceName: resourceName,
        displayName: sourceTypeData?.sourceType?.metadata.displayName,
      };
    }
    const name =
      configuration?.spec?.destinations?.[editProcessorsInfo.index].name;
    if (name) {
      const trimName = trimVersion(name);
      return {
        resourceName: `${trimName}-${editProcessorsInfo.index}`,
        displayName: trimName,
      };
    }
    return {
      resourceName: `dest${editProcessorsInfo.index}`,
      displayName: `dest${editProcessorsInfo.index}`,
    };
  }, [
    configuration?.spec?.destinations,
    configuration?.spec?.sources,
    editProcessorsInfo,
    sourceTypeData?.sourceType?.metadata.displayName,
  ]);

  const parentDisplayName = displayName ?? "unknown";
  const title = useMemo(() => {
    const kind =
      editProcessorsInfo?.resourceType === "source" ? "Source" : "Destination";
    return `${kind} ${parentDisplayName}: Processors`;
  }, [editProcessorsInfo?.resourceType, parentDisplayName]);

  const description =
    "Processors are run on data after it's received and prior to being sent to a destination. They will be executed in the order they appear below.";

  const snapshotPosition =
    editProcessorsInfo?.resourceType === "source" ? "s0" : "d0";

  const telemetryTypes =
    (editProcessorsInfo?.resourceType === "source"
      ? sourceTypeData?.sourceType?.spec?.telemetryTypes
      : destinationTypeData?.destinationWithType.destinationType?.spec
          ?.telemetryTypes) ?? [];

  return (
    <ResourceDialogContextProvider onClose={handleClose} purpose={"edit"}>
      <SnapshotContextProvider
        pipelineType={convertTelemetryType(selectedTelemetryType)}
        position={snapshotPosition}
        resourceName={resourceName}
        showAgentSelector={true}
      >
        <Dialog
          {...dialogProps}
          maxWidth={"xl"}
          fullWidth
          onClose={handleClose}
          classes={{
            root: styles.dialog,
            paper: styles.paper,
          }}
        >
          <Stack height="calc(100vh - 100px)" minHeight="800px">
            <TitleSection
              title={title}
              description={description}
              onClose={handleClose}
            />
            <Stack
              direction="row"
              width="100%"
              height="calc(100vh - 175px)"
              minHeight={"700px"}
              spacing={2}
              padding={2}
            >
              <div
                className={(mixins["flex-grow"], styles["snapshot-container"])}
              >
                <SnapshotSection readOnly={readOnly ?? false} />
              </div>

              <div className={styles["form-container"]}>
                <ResourceConfigurationEditor
                  initItems={processorsProp}
                  items={processors}
                  onItemsChange={setProcessors}
                  telemetryTypes={telemetryTypes}
                  kind={Kind.Processor}
                  refetchConfiguration={refetchConfiguration}
                  closeDialog={closeProcessorDialog}
                  updateInlineItems={handleUpdateInlineProcessors}
                />
              </div>
            </Stack>
          </Stack>
        </Dialog>
      </SnapshotContextProvider>
    </ResourceDialogContextProvider>
  );
};

function convertTelemetryType(telemetryType: string): PipelineType {
  switch (telemetryType) {
    case PipelineType.Logs:
      return PipelineType.Logs;
    case PipelineType.Metrics:
      return PipelineType.Metrics;
    case PipelineType.Traces:
      return PipelineType.Traces;
    default:
      return PipelineType.Logs;
  }
}

export const SnapshotSection: React.FC<{ readOnly: boolean }> = ({
  readOnly,
}) => {
  const { logs, metrics, traces, footer } = useSnapshot();
  return (
    <SnapshotConsole
      logs={logs}
      metrics={metrics}
      traces={traces}
      footer={footer}
      readOnly={readOnly}
    />
  );
};

export const ProcessorsBody: React.FC<{ readOnly: boolean }> = ({
  children,
  readOnly,
}) => {
  const { logs, metrics, traces, footer } = useSnapshot();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <SnapshotConsole
            logs={logs}
            metrics={metrics}
            traces={traces}
            footer={footer}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={5}>
          {children}
        </Grid>
      </Grid>
    </>
  );
};
