export enum SnapshotRegion {
  CONSOLE = 'snapshot-console',
  ROW = 'snapshot-row',
  ROW_SUMMARY = 'snapshot-row-summary',
  EXPANDER = 'snapshot-ex',
  ROW_NAME = 'snapshot-row-name',
  FIELD_ROW = 'snapshot-field-row',
  SEVERITY = 'snapshot-severity',
}

// ----------------------------------------------------------------------
// regions
//
// Generic event delegation. Using data-region allows us to quickly identify where in the
// dom an interaction took place without having to manage individual onClick event
// handlers. A single onClick handler is registered for the entire snapshot console.
//

/**
 * Sets the region of an element, stored in a data-region attribute.
 */
export function setRegion(element: HTMLElement, region: string): void {
  element.dataset['region'] = region;
}

/**
 * Gets the region of the current element, stored in a data-region attribute.
 */
export function getRegion(element: Element | undefined): string | undefined {
  return element instanceof HTMLElement ? element.dataset['region'] : undefined;
}

/**
 * Finds the first parent element with a region and returns the name of that region.
 */
export function findRegion(element: Element | null): string | undefined {
  if (element == null) {
    return;
  }
  const region = getRegion(element);
  if (region != null) {
    return region;
  }
  return findRegion(element.parentElement);
}

/**
 * Finds the first parent element with a region and returns the name of that region.
 */
export function findRegionElement(
  element: Element | null,
): HTMLElement | undefined {
  if (element == null) {
    return;
  }
  const region = getRegion(element);
  if (region != null) {
    return element as HTMLElement;
  }
  return findRegionElement(element.parentElement);
}

/**
 * Finds the first parent element with a region that matches the specified region.
 */
export function findElementWithRegion(
  element: Element | null,
  region: string,
): HTMLElement | undefined {
  if (element == null) {
    return;
  }
  const elementRegion = getRegion(element);
  if (elementRegion === region) {
    return element as HTMLElement;
  }
  return findElementWithRegion(element.parentElement, region);
}
