import { gql } from "@apollo/client";
import {
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Typography,
  Alert,
  AlertTitle,
  Button,
  Tooltip,
  Box,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CardContainer,
  TwoStepBreadcrumb,
} from "../../components/CardContainer";
import { ManageConfigForm } from "../../components/ManageConfigForm";
import { AgentTable } from "../../components/Tables/AgentTable";
import {
  useGetAgentAndConfigurationsQuery,
  useGetConfigurationQuery,
  useGetLatestMeasurementIntervalQuery,
} from "../../graphql/generated";
import { useAgentChangesContext } from "../../hooks/useAgentChanges";
import { RawConfigWizard } from "../configurations/wizards/RawConfigWizard";
import { useSnackbar } from "notistack";
import { labelAgents } from "../../utils/rest/label-agents";
import { RawConfigFormValues } from "../../types/forms";
import {
  hasAgentFeature,
  AgentFeatures,
  AgentStatus,
} from "../../types/agents";
import { withRequireLogin } from "../../contexts/RequireLogin";
import { AgentChangesProvider } from "../../contexts/AgentChanges";
import { RecentTelemetryDialog } from "../../components/RecentTelemetryDialog/RecentTelemetryDialog";
import { PipelineGraph } from "../../components/PipelineGraph/PipelineGraph";
import { Config } from "../../components/ManageConfigForm/types";
import { ConfigurationSelect } from "../../components/ManageConfigForm/ConfigurationSelect";
import { classes } from "../../utils/styles";
import {
  DEFAULT_PERIOD,
  DEFAULT_TELEMETRY_TYPE,
  MeasurementControlBar,
} from "../../components/MeasurementControlBar";
import { UpgradeError } from "../../components/UpgradeError";
import { withEENavBar } from "../../components/EENavBar";
import { withRBAC } from "../../contexts/RBAC";
import { YamlEditor } from "../../components/YamlEditor";

import styles from "./agent-page.module.scss";
import mixins from "../../styles/mixins.module.scss";

gql`
  query GetAgentAndConfigurations($agentId: ID!) {
    agent(id: $agentId) {
      id
      name
      architecture
      operatingSystem
      labels
      hostName
      platform
      version
      macAddress
      remoteAddress
      home
      status
      connectedAt
      disconnectedAt
      errorMessage
      configuration {
        Collector
      }
      configurationResource {
        metadata {
          id
          version
          name
        }
        rendered
      }
      upgrade {
        status
        version
        error
      }
      upgradeAvailable
      features
    }
    configurations {
      configurations {
        metadata {
          id
          name
          version
          labels
        }
        spec {
          raw
        }
      }
    }
  }
`;

export const AgentPageContent: React.FC = () => {
  const { id } = useParams();
  const snackbar = useSnackbar();
  const [importOpen, setImportOpen] = useState(false);
  const [recentTelemetryOpen, setRecentTelemetryOpen] = useState(false);
  const [selectedTelemetry, setSelectedTelemetry] = useState(
    DEFAULT_TELEMETRY_TYPE
  );
  const [period, setPeriod] = useState<string>();
  const [measurementPeriods, setMeasurementPeriods] = useState<string[]>();

  // AgentChanges subscription to trigger a refetch.
  const agentChanges = useAgentChangesContext();

  const { data, refetch } = useGetAgentAndConfigurationsQuery({
    variables: { agentId: id ?? "" },
    fetchPolicy: "network-only",
  });

  const navigate = useNavigate();

  async function onImportSuccess(values: RawConfigFormValues) {
    if (data?.agent != null) {
      try {
        await labelAgents(
          [data.agent.id],
          { configuration: values.name },
          true
        );
      } catch (err) {
        snackbar.enqueueSnackbar("Failed to apply label to agent.", {
          variant: "error",
        });
      }
    }

    setImportOpen(false);
  }

  useEffect(() => {
    if (agentChanges.length > 0) {
      const thisAgent = agentChanges
        .map((c) => c.agent)
        .find((a) => a.id === id);
      if (thisAgent != null) {
        refetch();
      }
    }
  }, [agentChanges, id, refetch]);

  const currentConfig = useMemo(() => {
    if (data?.agent == null || data?.configurations == null) {
      return null;
    }

    const configName = data.agent.configurationResource?.metadata.name;
    if (configName == null) {
      return null;
    }

    return data.configurations.configurations.find(
      (c) => c.metadata.name === configName
    );
  }, [data?.agent, data?.configurations]);

  const currentConfigName = useMemo(() => {
    if (data?.agent == null || data?.configurations == null) {
      return null;
    }

    const configName = data.agent.configurationResource?.metadata.name;
    if (configName == null) {
      return null;
    }
    return configName;
  }, [data?.agent, data?.configurations]);

  // Get Configuration Data

  const configQuery = useGetConfigurationQuery({
    variables: { name: currentConfigName ?? "" },
    fetchPolicy: "cache-and-network",
  });

  useGetLatestMeasurementIntervalQuery({
    skip:
      configQuery.data?.configuration != null &&
      configQuery.data.configuration.spec.raw !== "",
    variables: {
      name: currentConfigName ?? "",
    },
    onCompleted(data) {
      if (data.configuration?.spec?.measurementInterval != null) {
        switch (data.configuration.spec.measurementInterval) {
          case "1m":
            setMeasurementPeriods(["1m", "5m", "1h", "24h"]);
            setPeriod("1m");
            break;
          case "5m":
            setMeasurementPeriods(["5m", "1h", "24h"]);
            setPeriod("5m");
            break;
          case "1h":
            setMeasurementPeriods(["1h", "24h"]);
            setPeriod("1h");
            break;
          case "24h":
            setMeasurementPeriods(["24h"]);
            setPeriod("24h");
            break;
          default:
            setMeasurementPeriods(["10s", "1m", "5m", "1h", "24h"]);
            setPeriod(DEFAULT_PERIOD);
        }
      }
    },
  });

  const configGraph = configQuery.data?.configuration;
  const [editing, setEditing] = useState(false);

  const [selectedConfig, setSelectedConfig] = useState<Config | undefined>(
    data?.configurations.configurations.find(
      (c) =>
        c.metadata.name === data?.agent?.configurationResource?.metadata.name
    )
  );

  const rawConfig = configGraph?.spec?.raw;

  const viewTelemetryButton = useMemo(() => {
    if (currentConfig?.spec.raw !== "") {
      return null;
    }

    let disableReason: string | null = null;

    if (
      data?.agent == null ||
      data.agent?.status === AgentStatus.DISCONNECTED
    ) {
      disableReason = "Cannot view recent telemetry, agent is disconnected.";
    }

    if (
      disableReason == null &&
      !hasAgentFeature(data!.agent!, AgentFeatures.AGENT_SUPPORTS_SNAPSHOTS)
    ) {
      disableReason =
        "Upgrade Agent to v1.8.0 or later to view recent telemetry.";
    }

    if (disableReason == null && data?.agent?.configurationResource == null) {
      disableReason =
        "Cannot view recent telemetry for an agent with an unmanaged configuration.";
    }

    if (disableReason != null) {
      return (
        <Tooltip title={disableReason} disableInteractive>
          <div style={{ display: "inline-block" }}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setRecentTelemetryOpen(true)}
              disabled
            >
              View Recent Telemetry
            </Button>
          </div>
        </Tooltip>
      );
    } else {
      return (
        <Button
          variant="outlined"
          size="small"
          onClick={() => setRecentTelemetryOpen(true)}
        >
          View Recent Telemetry
        </Button>
      );
    }
  }, [currentConfig?.spec.raw, data]);

  // Here we use the distinction between graphql returning null vs undefined.
  // If the agent is null then this agent doesn't exist, redirect to agents.
  if (data?.agent === null) {
    navigate("/agents");
    return null;
  }

  // Data is loading, return null for now.
  if (data === undefined || data.agent == null) {
    return null;
  }

  const upgradeError = data.agent?.upgrade?.error;

  const EditConfiguration: React.FC = () => {
    return (
      <>
        <ConfigurationSelect
          agent={data?.agent!}
          setSelectedConfig={setSelectedConfig}
          selectedConfig={selectedConfig}
          configurations={data.configurations?.configurations}
        />
      </>
    );
  };
  return (
    <>
      <TwoStepBreadcrumb
        navLink="/agents"
        navLabel="Agents"
        current={data.agent.name}
      />
      <CardContainer>
        <Grid container spacing={5}>
          <Grid item xs={12} lg={12}>
            <Typography
              variant="h5"
              classes={{ root: mixins["mb-2"] }}
              fontWeight={400}
            >
              Details
              <Stack display={"inline"} style={{ float: "right" }}>
                {viewTelemetryButton}
              </Stack>
            </Typography>

            <AgentTable agent={data.agent} />

            {upgradeError && (
              <Box marginTop="8px">
                <UpgradeError
                  agentId={data.agent.id}
                  upgradeError={data.agent?.upgrade?.error}
                  onClearFailure={() => {
                    snackbar.enqueueSnackbar("Oops! Something went wrong.", {
                      variant: "error",
                      key: "clear-upgrade-error",
                    });
                  }}
                  onClearSuccess={() => {
                    refetch();
                  }}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </CardContainer>

      <CardContainer>
        <Stack>
          {/* Edit configuration */}
          <ManageConfigForm
            agent={data.agent}
            configurations={data.configurations.configurations ?? []}
            onImport={() => setImportOpen(true)}
            editing={editing}
            setEditing={setEditing}
            selectedConfig={selectedConfig}
            setSelectedConfig={setSelectedConfig}
          />
          {editing && (
            <>
              <EditConfiguration />
              <Box sx={{ minHeight: 400 }}></Box>
            </>
          )}
          {data.agent.errorMessage && !editing && (
            <Alert
              severity="error"
              className={classes([mixins["mt-5"], mixins["mb-0"]])}
            >
              <AlertTitle>Error</AlertTitle>
              {data.agent.errorMessage}
            </Alert>
          )}
          {rawConfig && !editing && (
            <YamlEditor value={rawConfig} readOnly limitHeight />
          )}
          {!rawConfig && configGraph && !editing && (
            <div className={styles.grid}>
              <MeasurementControlBar
                telemetry={selectedTelemetry}
                onTelemetryTypeChange={setSelectedTelemetry}
                period={period ?? DEFAULT_PERIOD}
                onPeriodChange={setPeriod}
                periods={measurementPeriods}
              />
              <PipelineGraph
                agentId={data.agent.id}
                configurationName={`${data.agent.configurationResource?.metadata.name}:${data.agent.configurationResource?.metadata.version}`}
                selectedTelemetry={selectedTelemetry}
                period={period ?? DEFAULT_PERIOD}
                readOnly
              />
            </div>
          )}
        </Stack>
      </CardContainer>

      {/** Raw Config wizard for importing an agents config */}
      <Dialog
        open={importOpen}
        onClose={() => setImportOpen(false)}
        PaperComponent={EmptyComponent}
        scroll={"body"}
      >
        <DialogContent>
          <Stack justifyContent="center" alignItems="center" height="100%">
            <RawConfigWizard
              onClose={() => setImportOpen(false)}
              initialValues={{
                name: data.agent.name,
                description: `Imported configuration from agent ${data.agent.name}.`,
                fileName: "",
                rawConfig: data.agent.configuration?.Collector ?? "",
                platform: configPlatformFromAgentPlatform(data.agent.platform),
                secondaryPlatform: configSecondaryPlatformFromAgentPlatform(
                  data.agent.platform
                ),
              }}
              onSuccess={onImportSuccess}
              fromImport
            />
          </Stack>
        </DialogContent>
      </Dialog>

      {currentConfig?.spec.raw === "" && (
        <RecentTelemetryDialog
          open={recentTelemetryOpen}
          onClose={() => setRecentTelemetryOpen(false)}
          agentID={id!}
        />
      )}
    </>
  );
};

const EmptyComponent: React.FC = ({ children }) => {
  return <>{children}</>;
};

function configPlatformFromAgentPlatform(platform: string | null | undefined) {
  if (platform == null) return "linux";
  if (platform === "darwin") return "macos";
  if (platform.startsWith("kubernetes")) return "kubernetes";
  if (platform.startsWith("openshift")) return "openshift";
  return platform;
}

function configSecondaryPlatformFromAgentPlatform(
  platform: string | null | undefined
) {
  if (platform == null) return "";
  if (platform.startsWith("kubernetes")) return platform;
  if (platform.startsWith("openshift")) return platform;
  return "";
}

export const AgentPage = withRequireLogin(
  withRBAC(
    withEENavBar(() => (
      <AgentChangesProvider>
        <AgentPageContent />
      </AgentChangesProvider>
    ))
  )
);
