import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { withRequireLogin } from "../../contexts/RequireLogin";

import styles from "./eula-required.module.scss";
import mixins from "../../styles/mixins.module.scss";
import { CodeBlock } from "../../components/CodeBlock";

const EulaRequiredPageContent: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Stack
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      className={styles.stack}
    >
      <Card classes={{ root: styles.card }}>
        <CardHeader title="EULA Required" />
        <CardContent>
          <Typography>
            A EULA is required to be accepted to use{" "}
            <a
              href="https://observiq.com/solutions/bindplane-enterprise/"
              target="_blank"
              rel="noopener noreferrer"
            >
              BindPlane OP Enterprise edition
            </a>
            . Update your server configuration by running the following command
            to accept the EULA and restart the server to use the application.
            Visit{" "}
            <a
              href="https://observiq.com/legal/eula"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://observiq.com/legal/eula
            </a>{" "}
            to view the current EULA.
            <CodeBlock
              value="sudo BINDPLANE_CONFIG_HOME=/var/lib/bindplane /usr/local/bin/bindplane init server --config /etc/bindplane/config.yaml"
              paperClass={styles.code}
            />
            <br />
          </Typography>
        </CardContent>
        <CardActions className={styles.actions}>
          <Button
            href="mailto:support.bindplaneop@observiq.com"
            variant="outlined"
            color="secondary"
            className={mixins["mr-2"]}
          >
            Contact Support
          </Button>
          <Button onClick={() => navigate("/agents")} variant="contained">
            Refresh
          </Button>
        </CardActions>
      </Card>
    </Stack>
  );
};

export const EulaRequiredPage = withRequireLogin(EulaRequiredPageContent);
