import { Box, Button, Stack, Typography } from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import React, { useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { CardContainer } from "../../components/CardContainer";
import { PlusCircleIcon } from "../../components/Icons";
import { classes } from "../../utils/styles";
import { deleteAgents } from "../../utils/rest/delete-agents";
import { useSnackbar } from "notistack";
import { ConfirmDeleteResourceDialog } from "../../components/ConfirmDeleteResourceDialog";
import { withRequireLogin } from "../../contexts/RequireLogin";
import { isFunction } from "lodash";
import { upgradeAgents } from "../../utils/rest/upgrade-agent";
import { Role } from "../../graphql/generated";
import { hasPermission } from "../../utils/has-permission";
import { useRole } from "../../hooks/useRole";
import { RBACWrapper } from "../../components/RBACWrapper/RBACWrapper";
import { withRBAC } from "../../contexts/RBAC";
import { withEENavBar } from "../../components/EENavBar";
import { EEAgentsTable } from "../../components/EEAgentsTable";
import mixins from "../../styles/mixins.module.scss";

export const AGENTS_PAGE_QUERY_PARAM = "query";

export const AgentsPageContent: React.FC = () => {
  const [updatable, setUpdatable] = useState<GridRowSelectionModel>([]);
  const [deletable, setDeletable] = useState<GridRowSelectionModel>([]);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const clearSelectionModelFnRef = useRef<(() => void) | null>(null);

  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const role = useRole();

  function handleSelectUpdatable(agentIds: GridRowSelectionModel) {
    setUpdatable(agentIds);
  }
  function handleSelectDeletable(agentIds: GridRowSelectionModel) {
    setDeletable(agentIds);
  }

  async function handleDeleteAgents() {
    try {
      await deleteAgents(deletable as string[]);
      setDeletable([]);
      setDeleteConfirmOpen(false);
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to delete agents.", { variant: "error" });
    }
  }

  async function handleUpgradeAgents() {
    try {
      const errors = await upgradeAgents(updatable as string[]);

      if (isFunction(clearSelectionModelFnRef.current)) {
        clearSelectionModelFnRef.current();
      }

      setUpdatable([]);

      if (errors.length > 0) {
        console.error("Upgrade errors.", { errors });
      }
    } catch (err) {
      enqueueSnackbar("Failed to send upgrade request.", {
        variant: "error",
        key: "Failed to send upgrade request.",
      });
    }
  }

  const initQuery =
    new URLSearchParams(location.search).get(AGENTS_PAGE_QUERY_PARAM) ?? "";

  return (
    <>
      {/* --------------------- Delete Button and Confirmation --------------------- */}
      <ConfirmDeleteResourceDialog
        onDelete={handleDeleteAgents}
        onCancel={() => setDeleteConfirmOpen(false)}
        action={"delete"}
        open={deleteConfirmOpen}
        title={`Delete ${deletable.length} Disconnected Agent${
          deletable.length > 1 ? "s" : ""
        }?`}
      >
        <>
          <Typography>
            Agents will reappear in BindPlane OP if reconnected.
          </Typography>
        </>
      </ConfirmDeleteResourceDialog>
      <CardContainer>
        <Box style={{ height: "calc(100vh - 200px)" }}>
          <Stack height="100%">
            {/* --------------------- Upgrade Button and Confirmation ---------------------  */}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              height="48px"
              marginBottom="16px"
            >
              <Typography variant="h5">Agents</Typography>
              <Stack direction={"row"}>
                {updatable.length > 0 && (
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    classes={{
                      root: classes([mixins["float-right"], mixins["mr-3"]]),
                    }}
                    onClick={handleUpgradeAgents}
                  >
                    Upgrade {updatable.length} Outdated Agent
                    {updatable.length > 1 && "s"}
                  </Button>
                )}
                {deletable.length > 0 ? (
                  <Button
                    size="small"
                    variant="contained"
                    color="error"
                    classes={{ root: mixins["float-right"] }}
                    onClick={() => setDeleteConfirmOpen(true)}
                  >
                    Delete {deletable.length} Disconnected Agent
                    {deletable.length > 1 && "s"}
                  </Button>
                ) : (
                  <RBACWrapper requiredRole={Role.User}>
                    <Button
                      size="small"
                      component={Link}
                      variant={"contained"}
                      classes={{ root: mixins["float-right"] }}
                      to="/agents/install"
                      startIcon={<PlusCircleIcon height={20} width={20} />}
                    >
                      Install Agent
                    </Button>
                  </RBACWrapper>
                )}
              </Stack>
            </Stack>
            <Stack height="calc(100% - 64px)">
              <EEAgentsTable
                allowSelection={hasPermission(Role.User, role)}
                onDeletableAgentsSelected={handleSelectDeletable}
                onUpdatableAgentsSelected={handleSelectUpdatable}
                clearSelectionModelFnRef={clearSelectionModelFnRef}
                initQuery={initQuery}
                urlQuerySearchParam={AGENTS_PAGE_QUERY_PARAM}
                onboardingNoRowsOverlay
              />
            </Stack>
          </Stack>
        </Box>
      </CardContainer>
    </>
  );
};

export const AgentsPage = withRequireLogin(
  withRBAC(withEENavBar(AgentsPageContent))
);
