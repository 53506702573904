import { ConnectionHandler, registerConnectionHandler, unregisterConnectionHandler } from "../websocket";
import { useEffect } from "react";

/**
 * useOnWebsocketConnected will call the provided connection handler whenever the
 * GraphQL websocket connection for subscriptions is established.
 * This can be used to resync state when the websocket becomes
 * disconnected.
 * 
 * @param handler A handler function that should be run whenever the websocket connection is established.
 */
export function useOnWebsocketConnected(handler: ConnectionHandler) {
    useEffect(() => {
        const handle = registerConnectionHandler(handler)
        return () => {
            unregisterConnectionHandler(handle)
        }
    }, [handler])
}
