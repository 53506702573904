import {
  IconButton,
  Menu,
  MenuList,
  MenuItem,
  Typography,
  Button,
  Divider,
  Box,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState, SyntheticEvent, useEffect, useMemo } from "react";
import {
  GetUserInfoQuery,
  useChangeAccountMutation,
} from "../../graphql/generated";
import { NewAccountDialog } from "../NewAccountDialog";

import { CheckIcon, MoreVertical } from "../Icons";

import styles from "./account-switcher.module.scss";

interface AccountSwitcherProps {
  accounts?: GetUserInfoQuery["accounts"];
  activeAccountId?: string;
}

export const AccountSwitcher: React.FC<AccountSwitcherProps> = ({
  accounts,
  activeAccountId,
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = Boolean(anchorEl);
  const [createNew, setCreateNew] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [changeAccount, { error }] = useChangeAccountMutation();

  useEffect(() => {
    if (error != null) {
      console.error(error);
      enqueueSnackbar("Error changing account", {
        variant: "error",
        key: "change-account-error",
      });
    }
  }, [error, enqueueSnackbar]);

  function handleSwitcherClick(event: SyntheticEvent) {
    setAnchorEl(event.currentTarget);
  }

  function handleNewAccountSuccess() {
    setAnchorEl(null);
    setCreateNew(false);
    window.location.reload();
  }

  async function handleChangeAccount(accountId: string) {
    setAnchorEl(null);
    await changeAccount({
      variables: {
        input: {
          accountId,
        },
      },
    });
    window.location.reload();
  }

  const { current, other } = useMemo(
    () => sortAccountsList(accounts || [], activeAccountId || ""),
    [accounts, activeAccountId]
  );

  return (
    <>
      <IconButton
        sx={{ float: "right", marginRight: "4px" }}
        aria-label="Switch accounts"
        size="small"
        onClick={handleSwitcherClick}
      >
        <MoreVertical />
      </IconButton>

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        classes={{ paper: styles.menu }}
      >
        <Box className={styles["accounts-header"]}>
          <Typography fontWeight={600} marginLeft={1}>
            Accounts
          </Typography>
          <Button
            color="primary"
            size="small"
            sx={{ float: "right" }}
            onClick={() => setCreateNew(true)}
          >
            New
          </Button>
        </Box>
        <Divider />

        <MenuList>
          {current && (
            <MenuItem disabled selected classes={{ root: styles.current }}>
              <Typography className={styles["current-typo"]}>
                {current.metadata.displayName}
              </Typography>
              <CheckIcon className={styles.check} />
            </MenuItem>
          )}
          {other?.map((act) => (
            <MenuItem
              key={`switcher-${act.metadata.id}`}
              onClick={() => handleChangeAccount(act.metadata.id)}
            >
              <Typography>{act.metadata.displayName}</Typography>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>

      <NewAccountDialog
        open={createNew}
        onClose={() => setCreateNew(false)}
        onSuccess={handleNewAccountSuccess}
      />
    </>
  );
};

function sortAccountsList(
  accounts: GetUserInfoQuery["accounts"],
  currentAccountId: string
): {
  current: GetUserInfoQuery["accounts"][0] | undefined;
  other: GetUserInfoQuery["accounts"];
} {
  const current = accounts.find((act) => act.metadata.id === currentAccountId);
  const other = accounts.filter((act) => act.metadata.id !== currentAccountId);

  // sort other by displayName
  other.sort((a, b) => {
    const aDisplayName = a.metadata.displayName ?? "";
    const bDisplayName = b.metadata.displayName ?? "";
    return aDisplayName.toLowerCase().localeCompare(bDisplayName.toLowerCase());
  });

  return { current, other };
}
