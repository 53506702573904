import { LineChart } from "@mui/x-charts/LineChart";
import { formatBytes } from "../../utils/graph/utils";
import { SummaryGraphData } from "../../graphql/generated";
import { Stack } from "@mui/material";
import { add } from "date-fns";
import {
  SummaryInterval,
  SummaryPeriod,
} from "../../pages/overview/SummaryPage/SummaryPageContext";

import colors from "../../styles/colors";

interface DataSummaryGraphProps {
  summaryGraphData: SummaryGraphData;
  period: SummaryPeriod;
  interval: SummaryInterval;
}

export const DataSummaryGraph: React.FC<DataSummaryGraphProps> = ({
  summaryGraphData: dataset,
  period,
  interval,
}) => {
  const timeSeries = dataset.dataset.map((point) => {
    return {
      timestamp: new Date(point.timestamp),
      s0Value: point.s0Value,
      d1Value: point.d1Value,
    };
  });
  timeSeries.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());
  if (timeSeries.length < 2) {
    return (
      <Stack
        height={400}
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        No Data
      </Stack>
    );
  }

  return (
    <LineChart
      xAxis={[
        {
          dataKey: "timestamp",
          valueFormatter: (value) => formatTime(value, period),
          scaleType: "time",
          tickMinStep: tickStep(period),
          min: xMin(interval),
        },
      ]}
      series={[
        {
          dataKey: "s0Value",
          color: colors.black,
          curve: "linear",
          valueFormatter: formatBytes,
          showMark: false,
        },
        {
          dataKey: "d1Value",
          color: colors.pixelPointBlue,
          curve: "linear",
          valueFormatter: formatBytes,
          showMark: false,
        },
      ]}
      dataset={timeSeries}
      height={400}
      margin={{ left: 70, top: 48 }}
      yAxis={[{ valueFormatter: formatBytes, min: 0 }]}
    />
  );
};

function formatTime(time: Date, period: SummaryPeriod): string {
  switch (period) {
    case SummaryPeriod.TenSeconds:
      return time.toLocaleTimeString(undefined, {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      });
    case SummaryPeriod.OneMinute:
      return time.toLocaleTimeString(undefined, {
        hour: "numeric",
        minute: "numeric",
      });

    case SummaryPeriod.OneHour:
      return time.toLocaleTimeString(undefined, {
        hour: "numeric",
      });

    case SummaryPeriod.OneDay:
      return time.toLocaleDateString(undefined, {
        month: "numeric",
        day: "numeric",
      });
    default:
      return time.getMinutes().toString();
  }
}

function tickStep(period: string): number {
  switch (period) {
    case "1m":
      return 1000 * 60 * 5;
    case "1h":
      return 1000 * 60 * 60;
    case "24h":
      return 1000 * 60 * 60 * 24;
    default:
      return 1000 * 60 * 60 * 24;
  }
}

/**
 * xMin returns the start time for a graph given the interval.
 *
 * @param interval SummaryInterval, the interval to get the min date for
 * @returns Date, the start time for the graph
 */
export function xMin(interval: SummaryInterval): Date {
  const now = new Date();

  switch (interval) {
    case SummaryInterval.TenMinutes:
      return add(now, { minutes: -10 });
    case SummaryInterval.OneHour:
      return add(now, { hours: -1 });
    case SummaryInterval.OneDay:
      return add(now, { days: -1 });
    case SummaryInterval.ThirtyDays:
      return add(now, { days: -30 });
    case SummaryInterval.NinetyDays:
      return add(now, { days: -90 });
  }
}
