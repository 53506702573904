import { useState } from "react";
import {
  GetResourceTypesQuery,
  Kind,
  PipelineType,
  ResourceConfiguration,
} from "../../graphql/generated";
import { BPProcessor } from "../../utils/classes/processor";
import { BPExtension } from "../../utils/classes/extension";
import { useSnackbar } from "notistack";
import { isEqual } from "lodash";

import { BPResourceConfiguration } from "../../utils/classes";
import { applyResources } from "../../utils/rest/apply-resources";
import { UpdateStatus } from "../../types/resources";
import { AllItemsView } from "./AllItemsView";
import { EditResourceView } from "./EditResourceView";
import { FormValues } from "../ResourceConfigForm";
import { SelectView } from "./SelectView";
import { CreateConfigureView } from "./CreateConfigureView";

enum Page {
  MAIN,
  CREATE_RESOURCE_SELECT,
  CREATE_RESOURCE_CONFIGURE,
  EDIT_RESOURCE,
}

interface ResourceConfigurationEditorProps {
  // The initial list of processors or extensions to edit
  initItems: ResourceConfiguration[];
  // The stateful value of the current resource configurations
  items: ResourceConfiguration[];
  // Called to change the current state of resourceConfigurations
  onItemsChange: (newItems: ResourceConfiguration[]) => void;

  readOnly?: boolean;

  // The types of telemetry that are available for the pipeline, used to determine
  // which resource types can be used.
  telemetryTypes: PipelineType[];

  kind: Kind.Processor | Kind.Extension;
  refetchConfiguration: () => void;

  closeDialog: () => void;

  // updateInlineItems will be called when the user saves the inline items.
  // For processors it should handle saving the inline processors on that
  // item, and for extensions it should handle saving the inline extensions
  // on the configuration.  It should return true if saving was successful
  // and false if saving failed. There is no handling in the component, it is
  // up to the provider of the function to handle the saving and error.
  updateInlineItems: (items: ResourceConfiguration[]) => Promise<boolean>;
}

type ResourceType = GetResourceTypesQuery["resourceTypes"][0];

export const ResourceConfigurationEditor: React.FC<
  ResourceConfigurationEditorProps
> = ({
  items,
  initItems: itemsProp,
  onItemsChange,
  readOnly,
  telemetryTypes,
  kind,
  updateInlineItems,
  refetchConfiguration,
  closeDialog,
}) => {
  const [view, setView] = useState<Page>(Page.MAIN);
  const [newResourceType, setNewResourceType] = useState<ResourceType | null>(
    null
  );
  const [editingItemIndex, setEditingItemIndex] = useState<number>(-1);
  const [applyQueue, setApplyQueue] = useState<(BPProcessor | BPExtension)[]>(
    []
  );

  const { enqueueSnackbar } = useSnackbar();

  /* -------------------------------- Functions ------------------------------- */

  function handleReturnToAll() {
    setView(Page.MAIN);
    setNewResourceType(null);
  }

  // handleSelectNewProcessorType is called when a user selects a processor type
  // in the CreateProcessorSelect view.
  function handleSelectNewResourceType(type: ResourceType) {
    setNewResourceType(type);
    setView(Page.CREATE_RESOURCE_CONFIGURE);
  }

  // handleAddProcessor adds a new processor to the list of processors
  async function handleAddItem(formValues: FormValues) {
    const itemConfig = new BPResourceConfiguration();
    itemConfig.setParamsFromMap(formValues);
    itemConfig.type = newResourceType!.metadata.name;

    const newItems = [...items, itemConfig];
    onItemsChange(newItems);
    handleReturnToAll();
  }

  // handleSaveExistingInlineResource saves changes to an existing resourceConfiguration in the list
  function handleSaveExistingInlineResource(formValues: FormValues) {
    const itemConfig = new BPResourceConfiguration();
    itemConfig.setParamsFromMap(formValues);
    itemConfig.type = items[editingItemIndex].type;

    const newItems = [...items];
    newItems[editingItemIndex] = itemConfig;
    onItemsChange(newItems);

    handleReturnToAll();
  }

  // handleSaveExistingPersistentResource adds a processor to the apply queue
  function handleSaveExistingPersistentResource(
    resource: BPProcessor | BPExtension
  ) {
    const foundIndex = applyQueue.findIndex(
      (p) => p.name() === resource.name()
    );
    if (foundIndex !== -1) {
      const newApplyQueue = [...applyQueue];
      newApplyQueue[foundIndex] = resource;
      setApplyQueue(newApplyQueue);
    } else {
      setApplyQueue([...applyQueue, resource]);
    }

    handleReturnToAll();
  }

  // handleRemoveItem removes a processor from the list of processors
  async function handleRemoveItem(index: number) {
    const newItems = [...items];
    newItems.splice(index, 1);
    onItemsChange(newItems);

    handleReturnToAll();
  }

  // handleEditItemClick sets the editing index and switches to the edit page
  function handleEditItemClick(index: number) {
    setEditingItemIndex(index);
    setView(Page.EDIT_RESOURCE);
  }

  // handleSave saves the processors to the backend and closes the dialog.
  async function handleSave() {
    const inlineChange = !isEqual(itemsProp, items);
    const resourceChange = applyQueue.length > 0;
    var shouldCloseDialog = true;

    if (!inlineChange && !resourceChange) {
      closeDialog();
    }

    if (resourceChange) {
      const { updates } = await applyResources(applyQueue);
      if (updates.some((u) => u.status === UpdateStatus.INVALID)) {
        enqueueSnackbar("Failed to save resources", {
          variant: "error",
          key: "save-resources-error",
        });
        shouldCloseDialog = false;
      }
    }

    if (inlineChange) {
      shouldCloseDialog = await updateInlineItems(items);
    }

    if (shouldCloseDialog) {
      refetchConfiguration();
      closeDialog();
      enqueueSnackbar(`Saved ${kind}s! 🎉`, { variant: "success" });
    }
  }

  let current: JSX.Element;
  switch (view) {
    case Page.MAIN:
      current = (
        <AllItemsView
          resourceKind={kind}
          items={items}
          onAddItem={() => setView(Page.CREATE_RESOURCE_SELECT)}
          onEditItem={handleEditItemClick}
          onSave={handleSave}
          onItemsChange={onItemsChange}
          readOnly={Boolean(readOnly)}
        />
      );
      break;
    case Page.CREATE_RESOURCE_SELECT:
      current = (
        <SelectView
          resourceKind={kind}
          telemetryTypes={telemetryTypes}
          onBack={() => setView(Page.MAIN)}
          onSelect={handleSelectNewResourceType}
        />
      );
      break;
    case Page.CREATE_RESOURCE_CONFIGURE:
      current = (
        <CreateConfigureView
          resourceKind={kind}
          resourceType={newResourceType!}
          onBack={handleReturnToAll}
          onSave={handleAddItem}
          onClose={closeDialog}
        />
      );
      break;
    case Page.EDIT_RESOURCE:
      current = (
        <EditResourceView
          resourceKind={kind}
          items={items}
          editingIndex={editingItemIndex}
          applyQueue={applyQueue}
          onEditInlineSave={handleSaveExistingInlineResource}
          onEditPersistentResourceSave={handleSaveExistingPersistentResource}
          onBack={handleReturnToAll}
          onRemove={handleRemoveItem}
          readOnly={readOnly}
        />
      );
  }

  return current;
};
