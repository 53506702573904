import { add, formatDistance } from 'date-fns';

export function timeAgoInWords(date: Date, from: Date = new Date()): string {
  return formatDistance(date, from);
}

export function currentYear(): string {
  return new Date().getFullYear().toString();
}

export function addOneDay(date: Date | null): Date | null {
  if (!date) {
    return null;
  }
  return add(date, { days: 1 });
}
