import { Dialog, DialogContent, DialogProps, Stack } from "@mui/material";
import {
  Kind,
  PipelineType,
  ResourceConfiguration,
  Role,
  useGetConfigurationQuery,
} from "../../graphql/generated";
import { useRefetchOnConfigurationChange } from "../../hooks/useRefetchOnConfigurationChanges";
import { MinimumRequiredConfig } from "../PipelineGraph/PipelineGraph";
import { useEffect, useMemo, useState } from "react";
import { isEqual } from "lodash";
import { TitleSection } from "../DialogComponents";
import { ResourceConfigurationEditor } from "../ResourceConfigurationEditor";
import { hasPermission, useRole } from "../../contexts/RBAC";
import { BPConfiguration } from "../../utils/classes";
import { useSnackbar } from "notistack";
import { asLatestVersion } from "../../utils/version-helpers";

interface ExtensionsDialogProps {
  open: boolean;
  closeDialog: () => void;
  configurationName: string;
}

export const ExtensionsDialog: React.FC<ExtensionsDialogProps> = ({
  open,
  closeDialog,
  configurationName,
}) => {
  const latestConfigurationName = asLatestVersion(configurationName);
  const { refetch, data } = useGetConfigurationQuery({
    variables: {
      name: latestConfigurationName,
    },
    fetchPolicy: "network-only",
  });

  useRefetchOnConfigurationChange(configurationName, refetch);

  const role = useRole();
  const readOnly = !hasPermission(Role.User, role);

  const extensions = useMemo(() => {
    return data?.configuration?.spec.extensions ?? [];
  }, [data]);

  if (data == null || data.configuration == null) {
    return null;
  }

  return (
    <ExtensionsDialogComponent
      open={open}
      closeDialog={closeDialog}
      extensions={extensions}
      configuration={data.configuration}
      readOnly={readOnly}
      refetchConfiguration={refetch}
    />
  );
};

interface ExtensionsDialogComponentProps extends Omit<DialogProps, "onClose"> {
  extensions: ResourceConfiguration[];
  closeDialog: () => void;
  configuration: NonNullable<MinimumRequiredConfig>;
  refetchConfiguration: () => void;
  readOnly: boolean;
}

const ExtensionsDialogComponent: React.FC<ExtensionsDialogComponentProps> = ({
  extensions: extensionsProp,
  configuration,
  readOnly,
  closeDialog,
  refetchConfiguration,
  ...dialogProps
}) => {
  const [extensions, setExtensions] =
    useState<ResourceConfiguration[]>(extensionsProp);
  const { enqueueSnackbar } = useSnackbar();

  // If the extensions prop changes, update the extensions state
  useEffect(() => {
    setExtensions(extensionsProp);
  }, [extensionsProp, setExtensions]);

  // handleClose is called when a user clicks off the dialog or the "X" button
  function handleClose() {
    if (!isEqual(extensions, extensionsProp)) {
      const ok = window.confirm("Discard changes?");
      if (!ok) {
        return;
      }
      // reset form values if chooses to discard.
      setExtensions(extensionsProp);
    }

    closeDialog();
  }

  const title = `Configuration ${configuration.metadata!.name}: Extensions`;
  const description = `Extensions provide capabilities on top of the primary \
functionality of the agent. They will be started in the order they appear below \
and will be stopped in reverse order.`;

  async function handleUpdateInlineExtensions(
    newExtensions: ResourceConfiguration[]
  ) {
    const bpConfiguration = new BPConfiguration(configuration);
    bpConfiguration.setExtensions(newExtensions);

    try {
      await bpConfiguration.apply();
    } catch (e) {
      enqueueSnackbar("Failed to update extensions", { variant: "error" });
      console.error(e);
      return false;
    }
    return true;
  }

  return (
    <Dialog {...dialogProps} maxWidth="md" fullWidth onClose={handleClose}>
      <Stack>
        <TitleSection
          title={title}
          onClose={handleClose}
          description={description}
        />
        <DialogContent>
          <ResourceConfigurationEditor
            closeDialog={closeDialog}
            initItems={extensionsProp}
            items={extensions}
            kind={Kind.Extension}
            onItemsChange={setExtensions}
            refetchConfiguration={refetchConfiguration}
            telemetryTypes={[
              PipelineType.Logs,
              PipelineType.Metrics,
              PipelineType.Traces,
            ]}
            updateInlineItems={handleUpdateInlineExtensions}
          />
        </DialogContent>
      </Stack>
    </Dialog>
  );
};
