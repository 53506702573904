import { Card, CardContent, Stack, Typography } from "@mui/material";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BindplaneVersion } from "../../components/BindplaneVersion";
import { BindPlaneLogo } from "../../components/Logos";
import lights from "../../lights.png";
import { handleLoginResponse } from "./handleLoginResponse";

import { CYPRESS_GOOGLE_CREDENTIALS_KEY, CypressGoogleCredentials } from "../../utils/cypress";
import styles from "./login.module.scss";

interface GoogleLoginBody extends CredentialResponse {
  invitation: string | null;
}

export const GoogleLoginPage: React.FC = () => {
  const [error, setError] = useState<null | string>(null);
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const gCypress = localStorage.getItem(CYPRESS_GOOGLE_CREDENTIALS_KEY);

  async function onSuccess(res: CredentialResponse) {
    try {
      if (error) {
        setError(null);
      }

      const body: GoogleLoginBody = {
        ...res,
        invitation: searchParams.get("invitation"),
      };

      const resp = await fetch("/login", {
        method: "POST",
        body: JSON.stringify(body),
      });

      handleLoginResponse({
        status: resp.status,
        onSuccess: async () => {
          if (error) {
            setError(null);
          }

          localStorage.setItem("user", "googleUser");
          navigate("/agents");
        },
        on401: async () => {
          setError("Failed to login.");
        },
        on403: async () => {
          if (error) {
            setError(null);
          }
          localStorage.setItem("user", "googleUser");
          navigate("/account/new");
        },
        on409: async () => {
          if (error) {
            setError(null);
          }
          enqueueSnackbar("Sorry, the invitation is invalid.", {
            variant: "error",
          });
        },
        onFailure: async () => {
          setError("Failed to login.");
        },
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Oops! Something went wrong.", { variant: "error" });
    }
  }

  function onError() {
    setError("Login Failed");
  }

  // Automated tests will set credentials in local storage
  useEffect(() => {
    let googleCreds: CypressGoogleCredentials;
    if (gCypress) {
      try {
        googleCreds = JSON.parse(gCypress);
      } catch (err) {
        return;
      }
      const { token } = googleCreds;
      onSuccess({ credential: token });
    }
  });

  return (
    <>
      <div className={styles["login-page"]} data-testid="login-page">
        <img
          src={lights}
          alt="lights.png"
          width={"100%"}
          style={{
            position: "fixed",
            top: "-10rem",
            left: "-0.1rem",
          }}
        />
        <Stack alignItems={"center"} justifyContent={"center"}>
          <BindPlaneLogo width={225} height={60} className={styles.logo} />
          <Card classes={{ root: styles.card }}>
            <CardContent>
              <Typography variant="h5" fontWeight={600} marginBottom={3}>
                Sign In
              </Typography>
              <Stack alignItems={"center"}>
                <GoogleLogin
                  onSuccess={onSuccess}
                  onError={onError}
                  width={500}
                />
                {error && <Typography color="error">{error}</Typography>}
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </div>
      <footer>
        <BindplaneVersion />
      </footer>
    </>
  );
};
