import React from "react";
import { withRequireLogin } from "../../contexts/RequireLogin";
import {
  AssistedConfigWizard,
  AssistedWizardFormValues,
} from "./wizards/AssistedConfigWizard";
import { withRBAC } from "../../contexts/RBAC";
import { withEENavBar } from "../../components/EENavBar";
import { TwoStepBreadcrumb } from "../../components/CardContainer";
import { useLocation } from "react-router-dom";

export const SEARCH_PARAM_PLATFORM = "platform";
export const SEARCH_PARAM_SECONDARY_PLATFORM = "secondaryPlatform";

export const NewConfigurationPageContent: React.FC = () => {
  const location = useLocation();

  // Check query parameters for initial form values
  const searchParams = new URLSearchParams(location.search);
  const initValues: Partial<AssistedWizardFormValues> = {
    platform: searchParams.get(SEARCH_PARAM_PLATFORM) ?? undefined,
    secondaryPlatform:
      searchParams.get(SEARCH_PARAM_SECONDARY_PLATFORM) ?? undefined,
  };

  return (
    <>
      <TwoStepBreadcrumb
        navLink="/configurations"
        navLabel="Configurations"
        current="New"
        spacing="short"
      />
      <AssistedConfigWizard initValues={initValues} />
    </>
  );
};

export const NewConfigurationPage: React.FC = withRequireLogin(
  withRBAC(withEENavBar(NewConfigurationPageContent))
);
