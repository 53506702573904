import { createContext, useState, useEffect, useContext } from "react";
import { v4 } from "uuid";

interface InstallWizardContextValue {
  command: string | null;
  setCommand: (command: string) => void;
  installationId: string;
}

const defaultContextValue: InstallWizardContextValue = {
  command: null,
  setCommand: () => {},
  installationId: "",
};

const installWizardContext = createContext(defaultContextValue);

export const InstallWizardProvider: React.FC = ({ children }) => {
  const [command, setCommand] = useState<string | null>(null);
  const [installationId, setInstallationId] = useState<string | null>(null);

  // Generate a new installationId
  useEffect(() => {
    setInstallationId(newInstallID());
  }, []);

  if (installationId == null) {
    return null;
  }

  return (
    <installWizardContext.Provider
      value={{
        command,
        setCommand,
        installationId,
      }}
    >
      {children}
    </installWizardContext.Provider>
  );
};

export function useInstallWizard() {
  return useContext(installWizardContext);
}

function newInstallID() {
  return v4();
}
