import { IconButton, Menu, MenuItem, ListItemText } from "@mui/material";
import { SettingsIcon } from "../Icons";
import { useState } from "react";
import { AdvancedConfigDialog } from "../../pages/configurations/configuration/AdvancedConfigDialog";
import { ExtensionsDialog } from "../ExtensionsDialog";

interface ConfigDetailsMenuProps {
  configName: string;
}

export const ConfigDetailsMenu: React.FC<ConfigDetailsMenuProps> = ({
  configName,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [advancedDialogOpen, setAdvancedDialogOpen] = useState(false);
  const [extensionsDialogOpen, setExtensionsDialogOpen] = useState(false);

  async function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(e.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleAdvancedDialogClick() {
    setAdvancedDialogOpen(true);
    setAnchorEl(null);
  }

  function handleExtensionsDialogClick() {
    setExtensionsDialogOpen(true);
    setAnchorEl(null);
  }

  return (
    <>
      <IconButton
        data-testid="config-menu-button"
        id="config-menu-button"
        aria-controls={menuOpen ? "config-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        onClick={handleClick}
      >
        <SettingsIcon />
      </IconButton>
      <Menu
        id="config-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "config-menu-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        data-testid="config-details-menu"
      >
        <MenuItem onClick={handleAdvancedDialogClick}>
          <ListItemText>Advanced Configuration</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleExtensionsDialogClick}>
          <ListItemText>Manage Extensions</ListItemText>
        </MenuItem>
      </Menu>

      <AdvancedConfigDialog
        configName={configName}
        open={advancedDialogOpen}
        onClose={() => setAdvancedDialogOpen(false)}
        onSuccess={() => {
          setAdvancedDialogOpen(false);
          setAnchorEl(null);
        }}
      />

      <ExtensionsDialog
        open={extensionsDialogOpen}
        closeDialog={() => setExtensionsDialogOpen(false)}
        configurationName={configName}
      />
    </>
  );
};
